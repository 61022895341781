import { useIntl, defineMessages, MessageDescriptor } from 'react-intl';
import PasswordChangeValidationErrorCode from '../enums/PasswordChangeValidationErrorCode';
import TwoFAType from '../enums/TwoFAType';
import Error from '../interfaces/Error';
import Response from '../interfaces/Response';
import { useClientConfig } from '../helpers/themeHelpers';
import useCountdown from './useCountdown/useCountdown';
import { FC } from 'react';
import { RemoteError } from '../types/Remote';

interface Messages {
	[key: string]: MessageDescriptor;
}

const messages = defineMessages({
	A_3: {
		id: 'base.invalid_request',
		defaultMessage: 'Invalid request.'
	},
	A_4: {
		id: 'error.invalid_credentials',
		defaultMessage: 'Incorrect username or password'
	},
	A_8: {
		id: 'error.password_invalid_pattern',
		defaultMessage:
			'Password must contain upper and lower case letters, digits and should be between 8 and 256 characters in length'
	},
	A_9: {
		id: 'error.password_similar_to_name',
		defaultMessage: 'Password is too similar to user name'
	},
	A_10: {
		id: 'error.long_sequences_not_allowed',
		defaultMessage:
			'Password cannot contain 3 or more characters that resemble a sequence or are repeated.'
	},
	A_11: {
		id: 'error.password_recovery_count_limit',
		defaultMessage: 'Too many inquiries to recover password. Please try again later.'
	},
	A_34: {
		id: 'error.password_in_use',
		defaultMessage: 'You are already using this password'
	},
	A_35: {
		id: 'error.email_domain_banned',
		defaultMessage: 'This email domain is blacklisted'
	},
	A_36: {
		id: 'error.password_used_before',
		defaultMessage: 'You have already used this password'
	},
	A_38: {
		id: 'error.user_password_already_set',
		defaultMessage: 'User password already set'
	},
	A_39: {
		id: 'error.user_social_login_disabled',
		defaultMessage: 'Social login is disabled'
	},
	AP_23: {
		id: 'error.twofa_limit_reached',
		defaultMessage: '2FA submission limit is reached. You can try again in {Countdown} minutes',
	},
	SS_6: {
		id: 'error.country_is_restricted',
		defaultMessage: 'User country is restricted'
	},
	invalidEmail: {
		id: 'signup.email_error',
		defaultMessage: 'Invalid email address'
	},
	incorrectTwoFA: {
		id: 'error.incorrect_two_fa',
		defaultMessage: 'Incorrect 2FA code. Please try again'
	},
	acceptDuoPush: {
		id: 'error.accept_duo_push',
		defaultMessage: 'Please accept duo push'
	},
	acceptPushMe: {
		id: 'error.accept_push_me',
		defaultMessage: 'Approve the request in {client} app'
	},
	userBanned: {
		id: 'error.user_account_banned',
		defaultMessage: 'User account is locked. Please contact administration'
	},
	userTemporarilyBanned: {
		id: 'error.user_acount_temporarily_banned',
		defaultMessage: 'User account is locked until {date}'
	},
	defaultErrorMessage: {
		id: 'error.unexpected_error',
		defaultMessage: 'Unexpected error. Please contact support.'
	}
}) as Messages;

const passwordChangeErrorMessages = defineMessages({
	[PasswordChangeValidationErrorCode.A_8]: {
		id: 'passwordChangeError.invalidPattern',
		defaultMessage:
			'Password must contain upper and lower case letters, digits and should be between 8 and 256 characters in length.'
	},
	[PasswordChangeValidationErrorCode.A_9]: {
		id: 'passwordChangeError.passwordSimilarToName',
		defaultMessage: 'Password is too similar to user name.'
	},
	[PasswordChangeValidationErrorCode.A_10]: {
		id: 'passwordChangeError.longSequencesNotAllowed',
		defaultMessage:
			'Password cannot contain 3 or more characters that resemble a sequence or are repeated.'
	},
	[PasswordChangeValidationErrorCode.A_36]: {
		id: 'passwordChangeError.alreadyUsedBefore',
		defaultMessage: 'You have already used this password before'
	},
	[PasswordChangeValidationErrorCode.A_38]: {
		id: 'passwordChangeError.passwordAlreadySet',
		defaultMessage: 'Password already set'
	},
	[PasswordChangeValidationErrorCode.A_44]: {
		id: 'passwordChangeError.oldPasswordIsNotValid',
		defaultMessage: 'Old user password is not valid'
	}
});

enum MessageParameterKey {
	Username = 'username',
	ConfirmationToken = 'confirmationToken',
	Email = 'email'
}

const Countdown: FC<{ endDate: string }> = ({ endDate }) => {
	const { formattedTimeLeft } = useCountdown(endDate);
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{formattedTimeLeft}</>;
};

const useAuthErrorMessage = (response?: Response<RemoteError> | RemoteError | string | null) => {
	const { formatMessage } = useIntl();
	const { clientName } = useClientConfig();
	if (typeof response === 'undefined' || response === null) return undefined;
	if (typeof response === 'string') return response;

	const remoteError: RemoteError = 'data' in response ? response.data : response;

	if (remoteError) {
		const { errorCode, error, key, messageParameters, errorParameters } = remoteError;

		if (
			errorCode &&
			passwordChangeErrorMessages[errorCode as PasswordChangeValidationErrorCode]
		) {
			return formatMessage(
				passwordChangeErrorMessages[errorCode as PasswordChangeValidationErrorCode]
			);
		}

		if (error === '2fa_required' && '2fa_type' in remoteError && (remoteError as any)['2fa_type'] === TwoFAType.DUO_PUSH) {
			return formatMessage(messages.acceptDuoPush);
		}

		if (error === '2fa_required' && '2fa_type' in remoteError && (remoteError as any)['2fa_type'] === TwoFAType.PUSH_ME) {
			return formatMessage(messages.acceptPushMe, { client: clientName });
		}

		if (error === '2fa_incorrect') {
			return formatMessage(messages.incorrectTwoFA);
		}

		if (error === 'user_banned' || key === 'account_is_locked') {
			return formatMessage(messages.userBanned);
		}

		if (errorCode === 'GEN_3') {
			const errorKey = errorParameters ? errorParameters[0].key : null;
			switch (errorKey) {
				case MessageParameterKey.Email:
				case MessageParameterKey.Username:
					return formatMessage(messages.invalidEmail);
				default:
					return formatMessage(messages.A_3);
			}
		}

		if (errorCode === 'A_3') {
			const messageKey = messageParameters ? messageParameters[0].key : null;
			switch (messageKey) {
				case MessageParameterKey.Email:
					return formatMessage(messages.invalidEmail);
				default:
					return formatMessage(messages.A_3);
			}
		}

		if (errorCode === 'SS_2') {
			return formatMessage(messages.A_35);
		}

		if (errorCode === 'SS_3') {
			return formatMessage(messages.A_8);
		}

		if (errorCode === 'SS_4') {
			return formatMessage(messages.A_9);
		}

		if (errorCode === 'SS_5') {
			return formatMessage(messages.A_10);
		}

		if (error === 'user_temporarily_banned' && 'bannedUntil' in remoteError && typeof (remoteError as any).bannedUntil === 'string') {
			const bannedUntil = (remoteError as any).bannedUntil;
			const bannedUntilUtcString =
				bannedUntil.split('')[bannedUntil.split('').length - 1] === 'Z' // Currently backend misses the Z which is required by ISO 8601 if time is in UTC.
					? bannedUntil
					: `${bannedUntil}Z`; // Add it if it's missing so date is parsed as UTC
			const localizedBannedUntil = new Date(bannedUntilUtcString).toLocaleString(
				navigator.language || 'lt-LT'
			);
			return formatMessage(messages.userTemporarilyBanned, { date: localizedBannedUntil });
		}

		const endDate = messageParameters?.find((x: any) => x.key === 'rejected_until')?.value;
		if (errorCode === 'AP_23' && endDate) {
			return formatMessage(messages.AP_23, {
				Countdown: <Countdown endDate={`${endDate}Z`} />,
			});
		}

		return formatMessage(messages[errorCode!] || messages.defaultErrorMessage);
	}
	return formatMessage(messages.defaultErrorMessage);
};

export default useAuthErrorMessage;
