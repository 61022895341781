import { LanguageNames } from './interfaces/Theme';

export const languageNames: LanguageNames = {
	// supported languages inside entire project,
	// if you need to specify languages inside the config public/client-theme-config.json
	// add just first part like 'lt-LT', 'ru-RU' to supportedLanguages.
	// You will find an example inside the config of some client
	en: 'English',
	'lt-LT': 'Lietuvių',
	'ru-RU': 'Русский',
	'fr-FR': 'Français',
	'it-IT': 'Italiano',
	'et-EE': 'Eesti',
	'ko-KR': '한국어',
	'zh-CN': '简体中文',
	'nl-NL': 'Nederlands',
	'pt-BR': 'Português (BR)',
	'pt-PT': 'Português (PT)',
	ar: 'لعربية ',
	'pl-PL': 'Polski',
	'lv-LV': 'Latviešu',
	'es-ES': 'Español'
};
