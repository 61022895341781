import axios from 'axios';
import config from '../config';

const logout = ({ clientOnly } = {}) => {
	// Lets not use our preconfigured axios instance from 'useAxios' here.
	// Otherwise, if logout returns some weird error, the logic there could try to logout again etc.
	return axios
		.post(
			config.CLIENT_LOGOUT_ENDPOINT,
			{},
			{
				withCredentials: true,
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
		.catch(() => {
			// Do nothing - because let's still remove cookies from login page domain...
		})
		.then(() => {
			if (!clientOnly) {
				window.location.href = config.AUTH_SERVER_LOGOUT_ENDPOINT;
			}
		});
};

export default logout;

// 1.
