import { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import FormMessage, { FormMessageType } from '../../components/FormMessage/FormMessage';
import TwoFAType from '../../enums/TwoFAType';
import { extractMessageParemeters, getIsHiddenError } from '../../helpers/helpers';
import useManualSessionCheck from '../../hooks/useManualSessionCheck';
import Button, { ButtonType } from '../../components/Button/Button';
import RadioButton from '../../components/RadioButton/RadioButton';
import Spinner from '../../components/Spinner/Spinner';
import Modal from '../../components/Modal/Modal';
import VerifyCurrentAndNewForm from '../../containers/SecondFactorAuthSettings/VerifyCurrentAndNewForm/VerifyCurrentAndNewForm';
import VerifyCurrentForm from '../../containers/SecondFactorAuthSettings/VerifyCurrentForm/VerifyCurrentForm';
import styles from './SecondFactorAuthSettings.module.scss';
import AccessToken from '../../interfaces/AccessToken';
import endpoint from '../../endpoint';
import { useClientConfig } from '../../helpers/themeHelpers';
import useAuthErrorMessage from '../../hooks/useAuthErrorMessage';
import axiosInstance from '../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: '2ndfactorauth.title',
		defaultMessage: 'Second factor authentication'
	},
	update2FAOption: {
		id: 'profile.2factorauth_options_update',
		defaultMessage: 'Update 2FA option'
	},
	googleAuthentication: {
		id: 'profile.2factorauth_google',
		defaultMessage: 'Google authentication'
	},
	smsAuthentication: {
		id: 'profile.2factorauth_sms',
		defaultMessage: 'SMS authentication'
	},
	emailAuthentication: {
		id: 'profile.2factorauth_email',
		defaultMessage: 'Email authentication'
	},
	pushmeAuthentication: {
		id: 'profile.2factorauth_pushme',
		defaultMessage: '{client} app 2FA'
	},
	radioButtonTitle: {
		id: 'profile_2factorauth_title',
		defaultMessage: 'Please choose your two factor authentication method'
	},
	chooseSecondAuthMethod: {
		id: 'profileerror.2factor_auth_must',
		defaultMessage: 'You must choose your second factor authentication method'
	},
	updateYourData: {
		id: 'profilealert.2factor_auth_data',
		defaultMessage: 'Update your data'
	},
	dataSuccessfullyChanged: {
		id: 'profile.set_login_settings_success',
		defaultMessage: 'Data was successfully changed'
	},
	defaultErrorMessage: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.'
	},
	contactSupport: {
		id: 'base.contact_support',
		defaultMessage: 'Contact support'
	},
	whatIsApp2fa: {
		id: 'profile.2factorauth_pushme_title',
		defaultMessage: 'What is {client} app 2fa?'
	},
	whatIsApp2faBody: {
		id: 'profile.2factorauth_pushme_body',
		defaultMessage: `What is {client} app 2FA? You can now use your {client} app as a second-factor authentication method. This means you can confirm each transaction using your {client} app instead of receiving a code to email or phone. To enable the {client} app 2FA method, please download the {client} app.`
	},
	optionsTitle: {
		id: 'profile.2factorauth_options_title',
		defaultMessage: 'Second factor authentication options'
	},
	appStoreIconAlt: {
		id: 'imageAlt.appStoreIcon',
		defaultMessage: 'Click on this image to download the {client} app from App Store.'
	},
	playStoreIconAlt: {
		id: 'imageAlt.playStoreIcon',
		defaultMessage: 'Press this image to enter Google Play store and download the {client} app.'
	},
	twoFASettings: {
		id: 'profileSidebar.profileSidebar_2fa_settings',
		defaultMessage: '2FA Settings'
	}
});

enum VerifyType {
	CURRENT_AND_NEW = 'CURRENT_AND_NEW',
	CURRENT = 'CURRENT'
}

export interface VerifyParameters {
	currentType: TwoFAType;
	newGoogleAuthSecret?: string;
	newType: TwoFAType;
	newTypeTxId: string;
	txId: string;
	currentTypeTwoFaCode?: string;
}

interface Props {
	isLoggedIn: boolean;
	token?: AccessToken;
}

interface FormValues {
	newType: TwoFAType;
}

const initialFormState = {
	newType: TwoFAType.SMS
};

// TODO this has translations but mostly texts are just hardcoded, need to
// fully utilize translations

const SecondFactorAuthSettings = ({ isLoggedIn, token }: Props) => {
	const intl = useIntl();
	const [isRequestPending, setIsRequestPending] = useState<boolean>(true);
	const [successMessage, setSuccessMessage] = useState<string>();
	const [verifyType, setVerifyType] = useState<VerifyType | null>();
	const [verifyParameters, setVerifyParameters] = useState<VerifyParameters | null>();
	// const phoneNumberExists = phoneNumber && phoneNumber !== 'null';
	const [isScaRequired, setIsScaRequired] = useState(false);
	const [isPushMeDisabled, setIsPushMeDisabled] = useState(true);
	const [currentType, setCurrentType] = useState<TwoFAType | null>(null);
	const [formValues, setFormValues] = useState<FormValues>(initialFormState);
	const { check: manualSessionCheck } = useManualSessionCheck();
	const { clientName } = useClientConfig();

	const [error, setError] = useState<string>();
	const errorMessage = useAuthErrorMessage(error);

	const isFormValid = !!formValues.newType && !!currentType && formValues.newType !== currentType;

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setFormValues((prevState: FormValues) => ({
			...prevState,
			[event.target.name]: event.target.value
		}));
	};

	const handleSuccess = useCallback(
		(newType: TwoFAType) => {
			setVerifyType(null);
			setVerifyParameters(null);
			setSuccessMessage(intl.formatMessage(messages.dataSuccessfullyChanged));
			setCurrentType(newType);
			setFormValues({ newType });
			if (newType !== TwoFAType.PUSH_ME) {
				setIsPushMeDisabled(true);
			}
		},
		[intl]
	);

	const handleCancel = useCallback(() => {
		setFormValues({ newType: currentType as TwoFAType });
		setVerifyType(null);
		setVerifyParameters(null);
		if (currentType === TwoFAType.PUSH_ME) {
			setIsPushMeDisabled(false);
		}
	}, [currentType]);

	const fetchTwoFAInfo = async () => {
		if (!(await manualSessionCheck())) return;
		setIsRequestPending(true);
		axiosInstance
			.get(endpoint.twoFAInfo)
			.then((response) => {
				const { type, scaRequired } = response.data;
				setIsScaRequired(scaRequired);
				setFormValues({ newType: type });
				setCurrentType(type);
				if (type === TwoFAType.PUSH_ME) {
					setIsPushMeDisabled(false);
				}
			})
			.catch((error) => {
				if (!getIsHiddenError(error)) {
					setError(error?.response);
				}
			})
			.then(() => {
				setIsRequestPending(false);
			});
	};

	useEffect(() => {
		fetchTwoFAInfo();
		// eslint-disable-next-line
	}, []);

	const handleSubmit = useCallback(
		({ newType }: FormValues) => {
			setIsRequestPending(true);
			axiosInstance
				.post(endpoint.twoFATypeChangeInit, {
					language: intl.locale,
					newType: newType
				}, { skip2FA:  true })
				.then((res) => {
					setError(undefined);

					const { errorCode, messageParameters } = res.data;
					const parameters = extractMessageParemeters(messageParameters);

					if (errorCode === '2FA_11') {
						setVerifyType(VerifyType.CURRENT_AND_NEW);
						setVerifyParameters(parameters as unknown as VerifyParameters);
					}

					if (errorCode === '2FA_10') {
						setVerifyType(VerifyType.CURRENT);
						setVerifyParameters({
							...(parameters as unknown as VerifyParameters),
							newType: newType as TwoFAType
						});
					}
					setIsPushMeDisabled(true);
				})
				.catch((error) => {
					if (!getIsHiddenError(error)) {
						setError(error?.response);
					}
				})
				.then(() => {
					setIsRequestPending(false);
				});
		},
		[intl.locale]
	);

	if (isRequestPending) return <Spinner />;

	if (!isLoggedIn) {
		return <Redirect to="/login" />;
	}

	return (
		<>
			<h2>
				<FormattedMessage {...messages.title} />
			</h2>
			<p>
				<FormattedMessage {...messages.radioButtonTitle} />
			</p>
			<form
				className={styles.form}
				onSubmit={async (e) => {
					e.preventDefault();
					if (!(await manualSessionCheck())) return;
					if (!isFormValid || isRequestPending) return;
					handleSubmit(formValues);
				}}
			>
				{error && (
					<FormMessage type={FormMessageType.ERROR}>{errorMessage}</FormMessage>
				)}
				<RadioButton
					disabled={isPushMeDisabled}
					onChange={handleInputChange}
					label={`${clientName} Mobile App`}
					title={`${clientName} Mobile App`}
					value={TwoFAType.PUSH_ME}
					checked={formValues.newType === TwoFAType.PUSH_ME}
					name="newType"
				/>
				<RadioButton
					onChange={handleInputChange}
					label="SMS"
					title="2title"
					value={TwoFAType.SMS}
					checked={formValues.newType === TwoFAType.SMS}
					name="newType"
				/>

				<Button
					className={styles.button}
					type={ButtonType.SUBMIT}
					disabled={!isFormValid || isRequestPending}
				>
					<FormattedMessage {...messages.update2FAOption} />
				</Button>
			</form>
			<h3>
				<FormattedMessage
					{...messages.whatIsApp2fa}
					values={{
						client: <>{clientName}</>
					}}
				/>
			</h3>
			<p>
				<FormattedMessage
					{...messages.whatIsApp2faBody}
					values={{
						client: <>{clientName}</>
					}}
				/>
			</p>
			{verifyType === VerifyType.CURRENT_AND_NEW && (
				<Modal>
					<VerifyCurrentAndNewForm
						parameters={verifyParameters as VerifyParameters}
						email={token?.user_name!}
						onSuccess={handleSuccess}
						onError={setError}
						onCancel={handleCancel}
					/>
				</Modal>
			)}
			{verifyType === VerifyType.CURRENT && (
				<Modal>
					<VerifyCurrentForm
						parameters={verifyParameters as VerifyParameters}
						onSuccess={handleSuccess}
						onError={setError}
						onCancel={handleCancel}
					/>
				</Modal>
			)}
		</>
	);
};

export default SecondFactorAuthSettings;
