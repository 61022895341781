import { useState, useEffect } from 'react';
import logout from '../helpers/logout';
import endpoint from '../endpoint';
import axiosInstance from '../helpers/axiosInstance';

interface Props {
	pathnamesExcludedFromSessionCheck?: string[];
	isLoggedIn?: boolean;
}

const useSessionCheck = ({ pathnamesExcludedFromSessionCheck, isLoggedIn }: Props) => {
	const axios = axiosInstance;
	const [session, setSession] = useState();

	useEffect(() => {
		if (
			pathnamesExcludedFromSessionCheck &&
			pathnamesExcludedFromSessionCheck.includes(window.location.pathname)
		) {
			return;
		}
		if (!isLoggedIn) {
			window.history.pushState({}, '', 'login');
			return;
		}
		axios
			.get(endpoint.me)
			.then((response) => {
				setSession(response.data);
			})
			.catch((error) => {
				if (error?.response?.status === 401 || error?.response?.status === 403) {
					window.location.assign('/login');
				}
			});
	}, [axios, pathnamesExcludedFromSessionCheck, isLoggedIn]);

	return { session };
};

export default useSessionCheck;
