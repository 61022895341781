import React from 'react';
import styles from './FieldError.module.scss';

interface FieldErrorProps {
	error?: string;
}

const FieldError = ({ error }: FieldErrorProps) =>
	error ? <span className={styles.error}>{error}</span> : null;

export default FieldError;
