import React, { useCallback, useState, useRef, useEffect, ChangeEvent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Input from '../../../components/Input/Input';
import Button, { ButtonType, ButtonStyle } from '../../../components/Button/Button';
import FormMessage, { FormMessageType } from '../../../components/FormMessage/FormMessage';
import useScrollToTop from '../../../hooks/useScrollToTop/useScrollToTop';
import { VerifyParameters } from '../SecondFactorAuthSettings';
import TwoFAType from '../../../enums/TwoFAType';
import TwoFAResponseKeys from '../../../enums/TwoFAResponseKeys';
import endpoint from '../../../endpoint';
import { getIsHiddenError } from '../../../helpers/helpers';
import logout from '../../../helpers/logout';
import styles from './VerifyCurrentForm.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';
import axiosInstance from '../../../helpers/axiosInstance';

const messages = defineMessages({
	twoFATitle: {
		id: '2ndfactorauth.title',
		defaultMessage: 'Second factor authentication'
	},
	invalidKey: {
		id: '2ndfactorauth.invalid_key',
		defaultMessage: 'You must enter valid authentication key'
	},
	incorrectKey: {
		id: '2ndfactorauth.wrong_key',
		defaultMessage: 'Provided authentication key is not correct'
	},
	submit: {
		id: 'base.submit',
		defaultMessage: 'Submit'
	},
	tooManyRequests: {
		id: 'scvalidationexception.1001_too_many_requests',
		defaultMessage: 'Too many requests'
	},
	txIdLabel: {
		id: '2ndfactorauth.transaction_id',
		defaultMessage: 'Session ID'
	},
	googleAuthentication: {
		id: 'profile.2factorauth_google',
		defaultMessage: 'Google authentication'
	},
	smsAuthentication: {
		id: 'profile.2factorauth_sms',
		defaultMessage: 'SMS authentication'
	},
	emailAuthentication: {
		id: 'profile.2factorauth_email',
		defaultMessage: 'Email authentication'
	},
	googleAuthenticatorRegistration: {
		id: '2ndfactorauth.google_auth_qr',
		defaultMessage: 'Google authenticator registration'
	},
	cancel: {
		id: 'questionnaire.questionnaire_cancel',
		defaultMessage: 'Cancel'
	},
	pushMeCancelledOrExpired: {
		id: '2ndfactorauth.pushme_cancelled_or_expired',
		defaultMessage: '{client} App 2FA was cancelled or has expired'
	},
	close: {
		id: 'base.close',
		defaultMessage: 'Close'
	},
	summaryEmail: {
		id: '2ndfactorauth.summary_email',
		defaultMessage:
			'You should receive your authentication code via an email message. Enter the code to proceed further.'
	},
	summaryPushMe: {
		id: '2ndfactorauth.summary_pushme',
		defaultMessage:
			'You should receive a confirmation request in {client} application. Confirm the request to proceed further.'
	},
	summaryGoogle: {
		id: '2ndfactorauth.summary_google',
		defaultMessage: 'Google Authenticator is not an available 2FA option'
	},
	summarySMS: {
		id: '2ndfactorauth.summary_sms',
		defaultMessage:
			'You should receive your authentication code via sms message. Enter the code to proceed further.'
	}
});

export interface FormValues {
	currentTypeCode: string;
}

interface FormErrors {
	currentTypeCode: string;
}


interface Props {
	parameters: VerifyParameters;
	onSuccess: (newType: TwoFAType) => void;
	onError: (response: any) => void;
	onCancel: () => void;
}

const initialFormState = {
	currentTypeCode: ''
};

const VerifyCurrentForm = ({
	parameters: {
		currentType,
		newType,
		txId,
		newTypeTxId,
		currentTypeTwoFaCode
	},
	onError,
	onSuccess,
	onCancel
}: Props) => {
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [isCodeIncorrect, setIsCodeIncorrect] = useState(false);
	const intl = useIntl();
	const timeoutRef = useRef<number>();
	const [isPushMeCancelledOrExpired, setIsPushMeCancelledOrExpired] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>(initialFormState);
	const { clientName } = useClientConfig();

	useScrollToTop();

	const getAuthenticationSummary = useCallback((type: TwoFAType) => {
		switch (type) {
			case TwoFAType.EMAIL:
				return intl.formatMessage(messages.summaryEmail);
			case TwoFAType.PUSH_ME:
				return intl.formatMessage(messages.summaryPushMe, { client: clientName });
			case TwoFAType.GOOGLE:
				return intl.formatMessage(messages.summaryGoogle);
			case TwoFAType.SMS:
				return intl.formatMessage(messages.summarySMS);
			default:
				throw new Error('Unexpected verification type in current and new code 2fa form');
		}
	}, []);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setFormValues((prevState: FormValues) => ({
			...prevState,
			[event.target.name]: event.target.value
		}));
	};

	const handleSubmit = useCallback(
		(event?: any) => {
			event?.preventDefault();
			if (currentType !== TwoFAType.PUSH_ME) {
				setIsRequestPending(true);
			}
			const poll = () => {
				axiosInstance({
					method: 'post',
					url: endpoint.twoFATypeChangeVerify,
					data: {
						...formValues,
						newType,
						newTypeTxId,
						txId,
						recaptcha: undefined,
						...(currentType === TwoFAType.PUSH_ME
							? { currentTypeCode: currentTypeTwoFaCode }
							: {})
					},
					skip2FA: true,
				})
					.then((response: any) => {
						setIsRequestPending(false);
						setIsCodeIncorrect(false);
						onSuccess(response?.data?.type || newType);
					})
					.catch((error: any) => {
						const key = error?.response?.data?.key;
						if (
							key === TwoFAResponseKeys.PUSH_ME_TWO_FA_WAITING ||
							key === TwoFAResponseKeys.TWO_FA_WAITING
						) {
							timeoutRef.current = window.setTimeout(() => {
								poll();
							}, 5000);

							return;
						}
						setIsRequestPending(false);
						if (error?.response?.data?.errorCode === '2FA_2') {
							if (currentType === TwoFAType.PUSH_ME) {
								setIsPushMeCancelledOrExpired(true);
							} else {
								setIsCodeIncorrect(true);
							}
						} else {
							onError(error.response);
						}
					});
			};
			poll();
		},
		[
			newType,
			newTypeTxId,
			onError,
			onSuccess,
			txId,
			currentTypeTwoFaCode,
			currentType,
			formValues
		]
	);

	const handleCancel = () => {
		window.clearTimeout(timeoutRef.current);
		onCancel();
	};

	useEffect(() => {
		if (currentType === TwoFAType.PUSH_ME) handleSubmit();
		return () => handleCancel();
		// eslint-disable-next-line
	}, []);

	return (
		<div
			className={classNames(styles.container, {
				[styles.googleAuthContainer]: newType === TwoFAType.GOOGLE
			})}
		>
			<h2 className={styles.heading}>{intl.formatMessage(messages.twoFATitle)}</h2>

			<p>{getAuthenticationSummary(currentType as TwoFAType)}</p>

			<form onSubmit={handleSubmit}>
				<div className={styles.formGrid}>
					{(isCodeIncorrect || isPushMeCancelledOrExpired) && (
						<FormMessage type={FormMessageType.ERROR} className={styles.formError}>
							{
								<FormattedMessage
									{...(isPushMeCancelledOrExpired
										? messages.pushMeCancelledOrExpired
										: messages.incorrectKey)}
									values={{
										client: <>{clientName}</>
									}}
								/>
							}
						</FormMessage>
					)}
					<p>
						<FormattedMessage {...messages.txIdLabel} />: {txId}
					</p>
					{currentType !== TwoFAType.PUSH_ME && (
						<>
							<Input
								className={styles.input}
								type="text"
								disabled={isRequestPending}
								value={formValues.currentTypeCode}
								onChange={handleInputChange}
								name="currentTypeCode"
								inputMode="numeric"
							/>
							<div />
						</>
					)}

					<div className={styles.buttonContainer}>
						{currentType !== TwoFAType.PUSH_ME && (
							<Button
								className={styles.button}
								disabled={isRequestPending}
								type={ButtonType.SUBMIT}
							>
								<FormattedMessage {...messages.submit} />
							</Button>
						)}
						<Button
							className={classNames(styles.button, styles.buttonCancel)}
							disabled={isRequestPending}
							type={ButtonType.BUTTON}
							onClick={handleCancel}
							buttonStyle={
								currentType === TwoFAType.PUSH_ME
									? ButtonStyle.PRIMARY
									: ButtonStyle.LINK
							}
						>
							{
								<FormattedMessage
									{...(isPushMeCancelledOrExpired
										? messages.close
										: messages.cancel)}
								/>
							}
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default VerifyCurrentForm;
