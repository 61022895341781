import React from 'react';
import classNames from 'classnames';
import styles from './Spinner.module.scss';

export enum SpinnerSize {
	XS = 'XS',
	SM = 'SM',
	XL = 'XL'
}

export enum SpinnerStyle {
	PRIMARY = 'primary',
	SECONDARY = 'secondary'
}

interface SpinnerProps {
	className?: string;
	isFullScreen?: boolean;
	size?: SpinnerSize;
	style?: SpinnerStyle;
}

const Spinner = ({
	className,
	isFullScreen = false,
	size = SpinnerSize.XL,
	style = SpinnerStyle.PRIMARY
}: SpinnerProps) => (
	<div
		className={classNames(
			styles.spinnerContainer,
			{ [styles.fullScreen]: isFullScreen },
			className
		)}
	>
		<div className={classNames(styles.spinner, styles[size])}>
			<div className={classNames(styles.bounce1, styles[style])} />
			<div className={classNames(styles.bounce2, styles[style])} />
			<div className={classNames(styles.bounce3, styles[style])} />
		</div>
	</div>
);

export default Spinner;
