/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TwoFaCode, TwoFaRequest, TwoFaState } from './TwoFaTypes';

const initialState: TwoFaState = {
	request: null,
};

const slice = createSlice({
	initialState,
	name: 'twoFa',
	reducers: {
		setData: (state, { payload }: PayloadAction<TwoFaRequest>) => {
			state.request = payload;
		},
		setCode: (state, { payload }: PayloadAction<TwoFaCode>) => {
			if (state.request) state.request.errorCode = payload;
		},
		resetData: () => {
			return initialState;
		},
	},
});

export default slice;
