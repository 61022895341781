// const BASE_URI = 'http://localhost:3002';
const BASE_URI = window.location.origin;

// Use these for development on localhost.
// const endpoint = {
// 	me: `${BASE_URI}/api/api/me`,
// 	passwordChange: `${BASE_URI}/api/api/users/password`,
// 	twoFAInfo: `${BASE_URI}/api/api/2fa/`,
// 	twoFATypeChangeInit: `${BASE_URI}/api/api/2fa/me/type/change/init`,
// 	twoFATypeChangeVerify: `${BASE_URI}/api/api/2fa/me/type/change/verify`,
// 	devices: `${BASE_URI}/api/api/devices`,
// 	pushMeConnections: `/api/api/2fa/pushme/connections`,
// 	socialLoginPermissions: `${BASE_URI}/api/api/socialLoginPermissions`
// };

const endpoint = {
	me: `${BASE_URI}/api/me`,
	passwordChange: `${BASE_URI}/api/users/password`,
	twoFAInfo: `${BASE_URI}/api/2fa/`,
	twoFATypeChangeInit: `${BASE_URI}/api/2fa/me/type/change/init`,
	twoFATypeChangeVerify: `${BASE_URI}/api/2fa/me/type/change/verify`,
	devices: `${BASE_URI}/api/devices`,
	pushMeConnections: `/api/2fa/pushme/connections`,
	socialLoginPermissions: `${BASE_URI}/api/socialLoginPermissions`
};

export default endpoint;
