/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../interfaces/Theme';

export type AppState = {
	isMobileClient: boolean;
	client_config: Client;
}

export const initialState: AppState = {
	isMobileClient: false,
	client_config: {
		clientName: '',
		hasSocialLogin: true,
		hasPersonalSignup: true,
		hasBusinessSignup: true,
		hasConnectedDevices: true,
		hasAppleStore: false,
		hasGooglePlayStore: false,
		businessAccountEnv: '',
		recaptchaOpenBusinessSiteKey: '',
		livechatKey: '',
		socialLoginAppleEnabled: true,
		socialLoginFacebookEnabled: true,
		socialLoginGoogleEnabled: true,
		has2FA: true,
		supportEmail: '',
		privacyPolicy: {
			en: ''
		},
		hasLanguagesSwitch: true,
		supportedLanguages: ['en'],
		clientHomeURL: '',
		faviconURL: '',
		logo: { src: '', alt: '' },
		emailConfirmationImage: { src: '', alt: '' },
		doubleCheckImage: { src: '', alt: '' },
		passwordSuccessImage: { src: '', alt: '' },
		expiredImage: { src: '', alt: '' },
		rightColImage_1: { src: '', alt: '' },
		rightColImage_2: { src: '', alt: '' },
		rightColImageRedirectURL: ''
	},
};
const slice = createSlice({
	initialState,
	name: 'app',
	reducers: {
		setIsMobile: (state, { payload }: PayloadAction<boolean>) => {
			state.isMobileClient = payload;
		},
		setClientConfig: (state, { payload }: PayloadAction<Client>) => {
			state.client_config = payload;
		},
	},
});

export default slice;
