import { defineMessages, FormattedMessage } from 'react-intl';
import TwoFAType from '../../enums/TwoFAType';
import FormMessage, { FormMessageType } from '../FormMessage/FormMessage';
import { VIEW } from './TwoFAProvider';
import Button, { ButtonStyle } from '../Button/Button';
import CountdownInput from '../CountdownInput/CountdownInput';
import styles from './TwoFAProviderView.module.scss';
import { useClientConfig } from '../../helpers/themeHelpers';

const messages = defineMessages({
	twoFATitle: {
		id: '2ndfactorauth.title',
		defaultMessage: 'Second factor authentication'
	},
	submit: {
		id: 'base.submit',
		defaultMessage: 'Submit'
	},
	close: {
		id: 'base.close',
		defaultMessage: 'Close'
	},
	retry: {
		id: 'base.retry',
		defaultMessage: 'Retry'
	},
	incorrectTwoFA: {
		id: 'error.incorrect_two_fa',
		defaultMessage: 'Incorrect 2FA code. Please try again'
	},
	txIdLabel: {
		id: '2ndfactorauth.transaction_id',
		defaultMessage: 'Session ID:'
	},
	summaryEmail: {
		id: '2ndfactorauth.summary_email',
		defaultMessage:
			'You should receive your authentication code via an email message. Enter the code to proceed further.'
	},
	summaryPushMe: {
		id: '2ndfactorauth.summary_pushme',
		defaultMessage:
			'You should receive a confirmation request in {client} application. Confirm the request to proceed further.'
	},
	summarySMS: {
		id: '2ndfactorauth.summary_sms',
		defaultMessage:
			'You should receive your authentication code via sms message. Enter the code to proceed further.'
	},
	cancel: {
		id: 'base.cancel',
		defaultMessage: 'Cancel'
	},
	cancel: {
		id: 'base.cancel',
		defaultMessage: 'Cancel'
	},
	requestSMS: {
		id: '2ndfactorauth.requestSMS',
		defaultMessage: 'Request an SMS code',
	},
	requestCall: {
		id: '2ndfactorauth.requestCall',
		defaultMessage: 'Request a call',
	},
	usedUpRetryAttempts: {
		id: '2ndfactorauth.usedUpRetryAttempts',
		defaultMessage:
			'You have used up your retry attempts. Please repeat your last action to continue.'
	},
	expired: {
		id: '2ndfactorauth.expired',
		defaultMessage: 'Expired. You can still retry.'
	}
});

const TwoFAProviderView = ({
	code,
	formattedTimeLeft,
	isCodeError,
	onCancel,
	onCodeChange,
	onRetry,
	onRequestSMS,
	onRequestCall,
	onSubmit,
	txId,
	callEnabled,
	type,
	view,
	error,
	isExpired
}) => {
	const { clientName } = useClientConfig();
	return (
		<div>
			{type && (
				<div className={styles.container}>
					<div className={styles.content}>
						<h2 className={styles.heading}>
							<FormattedMessage {...messages.twoFATitle} />
						</h2>

						{view === VIEW.ERROR && (
							<>
								<FormMessage type={FormMessageType.ERROR}>
									{error}
								</FormMessage>
								<div className={styles.buttonContainer}>
									<Button onClick={onCancel}>
										<FormattedMessage {...messages.close} />
									</Button>
								</div>
							</>
						)}

						{view === VIEW.RETRY_COUNT_USED && (
							<>
								<p>
									<FormattedMessage {...messages.usedUpRetryAttempts} />{' '}
								</p>
								<div className={styles.buttonContainer}>
									<Button onClick={onCancel}>
										<FormattedMessage {...messages.close} />
									</Button>
								</div>
							</>
						)}

						{view === VIEW.EXPIRED && (
							<>
								<p>
									<FormattedMessage {...messages.expired} />
								</p>
								
								<div className={styles.buttonContainer}>
									<Button onClick={onRetry}>
										<FormattedMessage {...messages.retry} />
									</Button>
									{type === 'SMS' && callEnabled && 
										<Button buttonStyle={ButtonStyle.LINK} onClick={() => onRequestCall()} >
											<FormattedMessage {...messages.requestCall} />
										</Button>
									}
									{type === 'PUSH_ME' && 
										<Button buttonStyle={ButtonStyle.LINK} onClick={() => onRequestSMS(true)} >
											<FormattedMessage {...messages.requestSMS} />
										</Button>
									}
									<Button buttonStyle={ButtonStyle.LINK} onClick={onCancel}>
										<FormattedMessage {...messages.cancel} />
									</Button>
								</div>
							</>
						)}

						{view === VIEW.DEFAULT && (
							<>
								{isCodeError && (
									<FormMessage type={FormMessageType.ERROR}>
										<FormattedMessage {...messages.incorrectTwoFA} />
									</FormMessage>
								)}

								{type === TwoFAType.EMAIL && (
									<p>
										<FormattedMessage {...messages.summaryEmail} />
									</p>
								)}
								{type === TwoFAType.SMS && (
									<p>
										<FormattedMessage {...messages.summarySMS} />
									</p>
								)}
								{type === TwoFAType.PUSH_ME && (
									<p>
										<FormattedMessage
											{...messages.summaryPushMe}
											values={{
												client: <>{clientName}</>
											}}
										/>
									</p>
								)}

								<p>
									<FormattedMessage {...messages.txIdLabel} /> {txId}
								</p>

								{type === 'PUSH_ME' && (
									<div className={styles.pushmeTimer}>{formattedTimeLeft}</div>
								)}

								{type !== 'PUSH_ME' && (
									<CountdownInput
										className={styles.input}
										error={null}
										isCountdownHidden={false}
										isExpired={isExpired}
										formattedTimeLeft={formattedTimeLeft}
										type="text"
										value={code}
										onChange={onCodeChange}
										handleRetryButtonClick={onRetry}
										inputMode="numeric"
										pattern="\d*"
									/>
								)}

								<div className={styles.buttonContainer}>
									{type !== 'PUSH_ME' ? (
										<>
											<Button onClick={onSubmit}>
												<FormattedMessage {...messages.submit} />
											</Button>
											<Button
												buttonStyle={ButtonStyle.LINK}
												onClick={onCancel}
											>
												<FormattedMessage {...messages.cancel} />
											</Button>
										</>
									) : (
										<>
											<Button buttonStyle={ButtonStyle.LINK} onClick={() => onRequestSMS(false)} >
												<FormattedMessage {...messages.requestSMS} />
											</Button>
											<Button buttonStyle={ButtonStyle.LINK} onClick={onCancel}>
												<FormattedMessage {...messages.cancel} />
											</Button>
										</>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default TwoFAProviderView;
