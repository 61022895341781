import React, { AllHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import styles from './Button.module.scss';

export enum ButtonStyle {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	DANGER = 'danger',
	LINK = 'link'
}

export enum ButtonType {
	SUBMIT = 'submit',
	BUTTON = 'button',
	LINK = 'link',
	ANCHOR_LINK = 'anchor_link'
}

interface ButtonProps extends AllHTMLAttributes<HTMLElement> {
	buttonStyle?: ButtonStyle;
	children?: React.ReactNode;
	isLoading?: boolean;
	link?: string;
	type?: ButtonType;
}

const Button = ({
	buttonStyle = ButtonStyle.PRIMARY,
	className,
	isLoading = false,
	link,
	type,
	children,
	...attributes
}: ButtonProps) => {
	const buttonClassName = classNames(styles.button, styles[buttonStyle], className);

	switch (type) {
		case ButtonType.LINK:
			return (
				<Link className={buttonClassName} to={link as string} {...attributes}>
					{children}
				</Link>
			);
		case ButtonType.ANCHOR_LINK:
			return (
				<a className={buttonClassName} href={link} {...attributes}>
					{children}
				</a>
			);
		case ButtonType.BUTTON:
		case ButtonType.SUBMIT:
		default:
			return (
				<button className={buttonClassName} type={type} {...attributes}>
					{isLoading ? (
						<FontAwesomeIcon
							icon={faSpinner as IconDefinition}
							className={styles.animation}
						/>
					) : (
						children
					)}
				</button>
			);
	}
};

export default Button;
