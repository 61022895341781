import { useCallback, useEffect, useRef, useState } from 'react';
import { zeroPad } from '../../helpers/helpers';

export const useCountdown = (date, currDate = new Date().getTime(), refreshCycle = 200) => {
	const [isUserClockIncorrect, setIsUserClockIncorrect] = useState(false);
	const intervalRef = useRef(0);
	const endDate =
		typeof date === 'string'
			? date.split('')[date.split('').length - 1] === 'Z'
				? date
				: `${date}Z`
			: date || null;
	const difference = new Date(endDate || new Date().toISOString()).getTime() - currDate;

	const calculateTimeLeft = useCallback(() => {
		const timeLeft = {
			minutes: 0,
			seconds: 0,
			hours: 0,
		};

		if (difference > 0) {
			timeLeft.hours = Math.floor((difference / 1000) / 3600 % 3600);
			timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60);
			timeLeft.seconds = Math.floor((difference / 1000) % 60);
		}

		return timeLeft;
	}, [difference]);

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		if (difference <= 0) {
			window.clearInterval(intervalRef.current);
		} else {
			intervalRef.current = window.setInterval(() => {
				setTimeLeft(calculateTimeLeft());
			}, refreshCycle);
		}
		return () => window.clearInterval(intervalRef.current);
	}, [calculateTimeLeft, difference, refreshCycle]);

	useEffect(() => {
		if (date && difference <= 0) {
			setIsUserClockIncorrect(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	if (!endDate) {
		return {
			timeLeft: null,
			formattedTimeLeft: null,
			extendedFormattedTimeLeft: null,
			isExpired: false,
			isUserClockIncorrect
		};
	}

	return {
		timeLeft: difference,
		formattedTimeLeft: `${timeLeft.hours > 0 ? `${zeroPad(timeLeft.hours)}:` : ''}${zeroPad(timeLeft.minutes)}:${zeroPad(timeLeft.seconds)}`,
		isExpired: difference < 1,
		isUserClockIncorrect,
		parsedExpirationDate: endDate,
		difference
	};
};

export default useCountdown;
