import { DependencyList, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = (dependencies: DependencyList = []) => {
	const { pathname } = useLocation();

	useEffect(
		() => {
			window.scrollTo(0, 0);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		dependencies.length === 0 ? [pathname] : dependencies
	);

	return null;
};

export default useScrollToTop;
