export enum RemoteStatus {
	None,
	InProgress,
	Done,
	Error,
}

export type RemoteError = {
	errorCode: string;
	category: string;
	key: string;
	error_description: string;
	error: string;
	defaultMessage: string;
	spanId: string;
	traceId: string;
	errorParameters?: { [key: string]: string }[];
	messageParameters?: { [key: string]: string }[];
};

export type RemoteData<T> = {
	data?: T;
	status: RemoteStatus;
	error?: RemoteError;
};
