/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Language {
	value: string;
	label: string;
}

export type LanguageState = {
	activeLanguage: string;
};

export const initialState: LanguageState = {
	activeLanguage: 'en',
};
const slice = createSlice({
	initialState,
	name: 'language',
	reducers: {
		setLanguage: (state, { payload }: PayloadAction<string>) => {
			state.activeLanguage = payload;
		},
	},
});

export default slice;
