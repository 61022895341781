import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import styles from './RadioButton.module.scss';

interface Props {
	checked: boolean;
	disabled?: boolean;
	label: string;
	labelClassName?: string;
	name: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	title: string;
	value: string;
}

const RadioButton = ({
	label,
	value,
	labelClassName,
	disabled,
	name,
	onChange,
	...attributes
}: Props) => (
	<label
		className={classNames(styles.container, { [styles.disabled]: disabled }, labelClassName)}
	>
		<input
			type="radio"
			className={styles.input}
			disabled={disabled}
			value={value}
			name={name}
			onChange={onChange}
			{...attributes}
		/>
		<span className={styles.checkmark}></span>
		{label}
	</label>
);

export default RadioButton;
