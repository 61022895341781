import { useCallback, useState } from 'react';

enum AttemptScope {
	None,
	Attempting,
	Failed,
	Expired,
}

const useInputAttempts = () => {
	const [attempt, setAttempt] = useState(0);
	const [attemptScope, setAttemptScope] = useState(AttemptScope.None);

	const beginAttempt = useCallback(() => {
		switch (attemptScope) {
			case AttemptScope.None:
				setAttemptScope(AttemptScope.Attempting);
				break;
			default:
				break;
		}
	}, [attemptScope]);

	const endAttempt = useCallback(() => {
		switch (attemptScope) {
			case AttemptScope.Attempting:
			case AttemptScope.Failed:
			case AttemptScope.Expired:
				setAttemptScope(AttemptScope.None);
				break;
			default:
				break;
		}
	}, [attemptScope]);

	const registerFailedAttempt = useCallback(() => {
		setAttemptScope(AttemptScope.Failed);
		setAttempt((x) => x + 1);
	}, []);

	const fail = useCallback(() => {
		switch (attemptScope) {
			case AttemptScope.Attempting:
			case AttemptScope.Failed:
				registerFailedAttempt();
				break;
			default:
				break;
		}
	}, [attemptScope, registerFailedAttempt]);

	const expire = useCallback(() => {
		switch (attemptScope) {
			case AttemptScope.Attempting:
				registerFailedAttempt();
				break;
			default:
				break;
		}
	}, [attemptScope, registerFailedAttempt]);

	return [attempt, beginAttempt, fail, expire, endAttempt] as const;
};

export default useInputAttempts;
