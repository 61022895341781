import { useContext, useEffect, useState } from 'react';
import TwoFaContext, { TwoFaConsumerScope, TwoFaConsumers } from '../contexts/TwoFaContext';

export const useTwoFaProviderValue = () => {
	const [consumers, setConsumers] = useState<TwoFaConsumers>({});
	return [consumers, setConsumers] as const;
};

export const useRegisterTwoFaConsumer = (consumer: TwoFaConsumerScope) => {
	const { setConsumers } = useContext(TwoFaContext);
	const [consumerId] = useState(() => `${new Date().getTime()}_${Math.random()}`);;

	useEffect(() => {
		setConsumers((consumers) => ({
			...consumers,
			[consumerId]: consumer,
		}));
	}, [consumer, consumerId, setConsumers]);

	return [consumerId, consumer] as const;
};

export const useTwoFaConsumer = (consumerId?: string) => {
	const { consumers } = useContext(TwoFaContext);
	return consumerId ? consumers[consumerId] || null : null;
};
