import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, createContext } from 'react';
import { RemoteError } from '../types/Remote';

export type TwoFaConsumerScope = {
	onSuccess?: (data: AxiosResponse) => void;
	onFailure?: (error: AxiosError<RemoteError>) => void;
	onCancel?: () => void;
};

export type TwoFaConsumers = Record<string, TwoFaConsumerScope>;

type TwoFaContext = {
	consumers: TwoFaConsumers;
	setConsumers: Dispatch<SetStateAction<TwoFaConsumers>>;
};

export default createContext<TwoFaContext>({
	consumers: {},
	setConsumers: () => {},
});
