import { addMinutes, format } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';

const zeroPad = (num: number, places = 2) => {
	// eslint-disable-next-line no-mixed-operators
	const zero = places - num.toString().length + 1;
	return new Array(+(zero > 0 && zero)).join('0') + num;
};

export const useCountdown = (date: string | null, refreshCycle = 200) => {
	const [isUserClockIncorrect, setIsUserClockIncorrect] = useState(false);
	const intervalRef = useRef<number>(0);
	const endDate = date
		? new Date(`${format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS")}Z`).getTime()
		: new Date().getTime();
	const now = new Date(
		`${format(
			addMinutes(new Date(), new Date().getTimezoneOffset()),
			"yyyy-MM-dd'T'HH:mm:ss.SSS"
		)}Z`
	).getTime();
	const difference = endDate - now;

	const calculateTimeLeft = useCallback(() => {
		const timeLeft = {
			minutes: 0,
			seconds: 0,
		};

		if (difference > 0) {
			timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60);
			timeLeft.seconds = Math.floor((difference / 1000) % 60);
		}

		return timeLeft;
	}, [difference]);

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		if (difference <= 0) {
			window.clearInterval(intervalRef.current);
		} else {
			intervalRef.current = window.setInterval(() => {
				setTimeLeft(calculateTimeLeft());
			}, refreshCycle);
		}
		return () => window.clearInterval(intervalRef.current);
	}, [calculateTimeLeft, difference, refreshCycle]);

	useEffect(() => {
		if (date && difference <= 0) {
			setIsUserClockIncorrect(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	if (!endDate) {
		return {
			timeLeft: null,
			formattedTimeLeft: null,
			isExpired: false,
			isUserClockIncorrect,
		};
	}

	return {
		timeLeft: difference,
		formattedTimeLeft: `${zeroPad(timeLeft.minutes)}:${zeroPad(timeLeft.seconds)}`,
		isExpired: difference < 1,
		isUserClockIncorrect,
		parsedExpirationDate: endDate,
		difference,
	};
};

export default useCountdown;
