import { UserManager } from 'oidc-client-ts';
import config from '../config';
import logout from '../helpers/logout';
import endpoint from '../endpoint';
import axiosInstance from '../helpers/axiosInstance';
import { useCallback } from 'react';

// Usage example:
// if (!(await manualSessionCheck())) return;

const userManager = new UserManager({
	authority: config.AUTHORITY,
	client_id: config.CLIENT_ID,
	redirect_uri: config.REDIRECT_URI,
	scope: config.SCOPE,
	metadata: {
		issuer: config.ISSUER,
		token_endpoint: config.TOKEN_ENDPOINT,
		authorization_endpoint: config.AUTHORIZATION_ENDPOINT
	},
	loadUserInfo: false,
	response_type: 'code'
});

const useManualSessionCheck = () => {
	const check = useCallback(() => {
		return axiosInstance
			.get(endpoint.me)
			.then(() => {
				return true;
			})
			.catch((error) => {
				if (error?.response?.status === 401 || error?.response?.status === 403) {
					logout({ clientOnly: true }).then(() => {
						userManager.signinRedirect();
					});
					return false;
				}
				return true;
			});
	}, []);
	
	return {
		check
	};
};

export default useManualSessionCheck;
