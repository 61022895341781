const localDevelopmentConfig = {
	AUTH_SERVER_LOGOUT_ENDPOINT:
		'https://bnk-auth-test.spectrofinance.lt/api/users/logout?redirectUri=http://localhost:3002/login',
	AUTHORITY: 'bnk-auth-test.spectrofinance.lt/oauth',
	AUTHORIZATION_ENDPOINT: 'https://bnk-auth-test.spectrofinance.lt/oauth/authorize',
	CLIENT_ID: 'authorization-server_account-ui_local',
	CLIENT_LOGOUT_ENDPOINT: 'http://localhost:3002/api/proxy/logout',
	ISSUER: 'https://bnk-auth-test.spectrofinance.lt/',
	REDIRECT_URI: 'http://localhost:3002/login',
	SCOPE: 'default',
	SIGNUP_URI: 'https://bnk-auth-test.spectrofinance.lt.com/sign-up',
	TOKEN_ENDPOINT: '/proxy-token',
	PASSWORD_CHANGE_SUCCESS_URI: 'https://bnk-auth-test.spectrofinance.lt/login/password-changed',
	LANG_COOKIE_NAME: 'userLang',
	LANG_COOKIE_DOMAIN: '',
	CLIENT_NAME: 'BANKERA'
};

const configDefaults = {
	SOCIAL_LOGIN_PERMISSION_VIEW_ENABLED: true,
	LANG_COOKIE_NAME: 'userLang'
};

const config = {
	...configDefaults,
	...(process.env.NODE_ENV === 'development' ? localDevelopmentConfig : {}),
	...window.uiConfig
};

export default config;
