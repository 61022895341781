import { useState, useCallback, useRef } from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOnClickOutside from '../../hooks/useClickOutside';
import styles from './LanguageSwitcher.module.scss';
import { languageNames } from '../../themeConfig';

interface LanguageSwitcherProps {
	onLanguageChange: (lang: string) => void;
	className?: string;
	value?: string;
	supportedLanguages?: string[];
}

export const RTLLanguages = ['ar'];

const LanguageSwitcher = ({
	onLanguageChange,
	className,
	value,
	supportedLanguages = ['en']
}: LanguageSwitcherProps) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(containerRef, () => setIsMenuOpen(false));

	// used to filter languages which are passed from client config
	// as specific client uses specific languages
	const filteredLanguages = Object.keys(languageNames).filter((key) =>
		supportedLanguages.find((e) => e.includes(key))
	);
	const options = filteredLanguages.map((key) => ({
		value: key,
		label: languageNames[key]
	}));

	const selectedOption = options.find((option) => option.value === value) || options[0];

	const handleLanguageChange = useCallback(
		(option) => {
			setIsMenuOpen(false);
			onLanguageChange(option.value);
		},
		[onLanguageChange]
	);

	return (
		<div
			data-cy="l-switcher"
			ref={containerRef}
			className={classNames(styles.container, className)}
		>
			<span
				className={classNames(styles.selectedValue, { [styles.active]: isMenuOpen })}
				onClick={() => setIsMenuOpen(!isMenuOpen)}
			>
				{selectedOption.label}
				<FontAwesomeIcon
					className={styles.icon}
					/* @ts-ignore */
					icon={faCaretDown}
				/>
			</span>
			<div className={classNames(styles.listWrapper, { [styles.visible]: isMenuOpen })}>
				<ul className={styles.list}>
					{options.map((option) => (
						<li
							key={option.value}
							onClick={() => handleLanguageChange(option)}
							className={styles.listItem}
						>
							{option.label}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default LanguageSwitcher;
