import axios, { AxiosResponse, AxiosError, CreateAxiosDefaults, AxiosRequestConfig } from 'axios';
import AuthErrorCode from '../enums/AuthErrorCode';
import AuthErrorKey from '../enums/AuthErrorKey';
import axios2FA from './axios2FA';
import store from '../redux/Store';
import { TwoFaCode } from '../redux/TwoFaSlice/TwoFaTypes';
import { getAccessToken } from './helpers';

declare module 'axios' {
	export interface AxiosRequestConfig {
		skipAuth?: boolean;
		skip2FA?: boolean;
		twoFaConsumerId?: string;
	}
}

export const config: CreateAxiosDefaults = {
	headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' },
	withCredentials: true,
	transformRequest: (data: any) => {
		if (!data || data instanceof FormData || data instanceof URLSearchParams) return data;
		return JSON.stringify(data);
	},
};

export const responseInterceptors = [
	(response: AxiosResponse) => response,
	(error: AxiosError<any>) => {
		if (error && error.response) {
			const { response } = error;
			if (
				response?.data?.errorCode === TwoFaCode.TWO_FA_REQUIRED &&
				!response.config.skip2FA
			) {
				axios2FA(store, response?.config, response.data, response?.data?.errorCode);
			}

			if (response.status === 401 && !error.config?.skipAuth) {
				const { data } = response;
				if (
					(data.error === AuthErrorKey.UNAUTHORIZED &&
						data.error_description === AuthErrorKey.FULL_AUTHENTICATION_REQUIRED) ||
					data.errorCode === AuthErrorCode.AP_7 ||
					data.error === AuthErrorKey.INVALID_TOKEN
				) {
					window.location.assign('/login');
				}
			}

			if (response.status === 403 && response.data && !error.config?.skipAuth) {
				const { data } = response;

				if (data.key === AuthErrorKey.ACCESS_DENIED) {
					window.location.assign('/login');
				}
			}
		}
		throw error;
	},
] as const;

const isCsrfSafeMethod = (method?: string) => {
	const m = method?.toUpperCase();
	return m === 'GET' || m === 'HEAD' || m === 'OPTIONS';
};

export const requestInterceptor: any = [
	(config: AxiosRequestConfig) => {
		if (!isCsrfSafeMethod(config.method)) {
			const { csrf_token } = (getAccessToken() as any) || { csrf_token: ''};
			return { ...config, headers: {
				...config.headers,
				'CSRF-Token': csrf_token,
			}};
		}
		return config;
	},
	(error: AxiosError) => {
		return Promise.reject(error);
	}
];

const axiosInstance = axios.create(config);

axiosInstance.interceptors.response.use(...responseInterceptors);
axiosInstance.interceptors.request.use(...requestInterceptor);

export default axiosInstance;
