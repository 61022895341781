import { useState, useEffect, useRef, useContext } from 'react';
import { IntlProvider, defineMessages, FormattedMessage } from 'react-intl';
import { BrowserRouter as Router, Switch, Route, NavLink, Redirect } from 'react-router-dom';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import Login from './containers/Login/Login';
import SecondFactorAuthSettings from './containers/SecondFactorAuthSettings/SecondFactorAuthSettings';
import ConnectedDevices from './containers/ConnectedDevices/ConnectedDevices';
import SocialLoginPermissions from './containers/SocialLoginPermissions/SocialLoginPermissions';
import Button, { ButtonStyle } from './components/Button/Button';
import TwoFAProvider from './components/TwoFAProvider/TwoFAProvider';
import LanguageSwitcher from './components/LanguageSwitcher/LanguageSwitcher';
import useSessionCheck from './hooks/useSessionCheck';
import Cookie from 'js-cookie';
import { getAccessToken } from './helpers/helpers';
import logout from './helpers/logout';
import config from './config';
import styles from './App.module.scss';
import './stylesheets/global.scss';
import { getExternalThemeConfig, useClientConfig, useTheme } from './helpers/themeHelpers';
import AppActions from './redux/AppSlice/AppActions';
import { RTLLanguages } from './components/LanguageSwitcher/LanguageSwitcher';
import { useSelector } from 'react-redux';
import { getActiveLanguage } from './redux/LanguageSlice/LanguageSelectors';
import LanguageActions from './redux/LanguageSlice/LanguageActions';
import { useAppDispatch } from './redux/Store';
import { getTwoFaRequest } from './redux/TwoFaSlice/TwoFaSelectors';
import TwoFaContext from './contexts/TwoFaContext';
import { useTwoFaProviderValue } from './hooks/useTwoFa';

export const HOME_PAGE = '/change-password';
const pathnamesExcludedFromSessionCheck = ['/login'];

const navMessages = defineMessages({
	logout: {
		id: 'base.logout',
		defaultMessage: 'Log out'
	},
	securitySettings: {
		id: 'base.securitySettings',
		defaultMessage: 'Security settings'
	},
	changePassword: {
		id: 'changePassword.changePassword',
		defaultMessage: 'Change password'
	},
	secondFactorAuthentication: {
		id: '2ndfactorauth.title',
		defaultMessage: 'Second factor authentication'
	},
	connectedDevices: {
		id: 'security.connectedDevices_title',
		defaultMessage: 'Connected devices'
	},
	socialLoginPermissions: {
		id: 'profile.profile_social_login_permissions',
		defaultMessage: 'Social Login Permissions'
	}
});

export default function App() {
	const dispatch = useAppDispatch();
	const twoFa = useSelector(getTwoFaRequest);
	const [consumers, setConsumers] = useTwoFaProviderValue();
	const token = getAccessToken() as any;
	const [isLoggedIn, setIsLoggedIn] = useState(!!token?.user_id);
	const [messages, setMessages] = useState({});
	const lang = useSelector(getActiveLanguage);
	const [isAppLoading, setIsAppLoading] = useState(true);
	const {
		logo,
		hasLanguagesSwitch,
		hasSocialLogin,
		hasConnectedDevices,
		has2FA,
		supportedLanguages
	} = useClientConfig();

	useSessionCheck({ pathnamesExcludedFromSessionCheck, isLoggedIn });

	useEffect(() => {
		const savedLang = Cookie.get(config.LANG_COOKIE_NAME);
		if (savedLang) LanguageActions.setLanguage(savedLang);
	}, []);

	useEffect(() => {
		if (RTLLanguages.includes(lang.toLowerCase())) {
			document.body.setAttribute('dir', 'rtl');
		} else {
			document.body.setAttribute('dir', 'ltr');
		}
	}, [lang]);

	useEffect(() => {
		const filename = process.env.NODE_ENV === 'development' ? 'defaultLanguage' : lang;
		Cookie.set(config.LANG_COOKIE_NAME, lang, {
			...(config.LANG_COOKIE_DOMAIN ? { domain: config.LANG_COOKIE_DOMAIN } : {})
		});
		import(`./translations/${filename}.json`).then((importedMessages) =>
			setMessages(importedMessages)
		);
	}, [lang]);

	const theme = useRef({});
	useEffect(() => {
		const loadConfig = async () => {
			const { externalClientConfig, externalThemeConfig } = await getExternalThemeConfig();

			theme.current = externalThemeConfig;

			// load client config
			dispatch(AppActions.setClientConfig(externalClientConfig as any));
			setIsAppLoading(false);
		};
		loadConfig();
	}, []);
	// load theme variables
	useTheme(theme.current);

	return (
		<IntlProvider defaultLocale="en" locale={lang} messages={messages}>
			<TwoFaContext.Provider value={{ consumers, setConsumers }}>
				{twoFa &&  <TwoFAProvider />}
				{!isAppLoading && (
						<Router>
							<div className={styles.container}>
								<header className={styles.header}>
									<img className={styles.logo} src={logo.src} alt={logo.alt} />
									<div className={styles.userInfo}>
										{isLoggedIn && (
											<>
												<span className={styles.username}>
													{token.user_name}
												</span>
												<Button
													onClick={() => logout()}
													className={styles.logout}
													buttonStyle={ButtonStyle.LINK}
												>
													<FormattedMessage {...navMessages.logout} />
												</Button>
											</>
										)}
										{hasLanguagesSwitch && (
											<LanguageSwitcher
												className={styles.languageSwithcer}
												value={lang}
												onLanguageChange={(lang) => {
													LanguageActions.setLanguage(lang);
												}}
												supportedLanguages={supportedLanguages}
											/>
										)}
									</div>
									<h1 className={styles.siteTitle}>
										<FormattedMessage {...navMessages.securitySettings} />
									</h1>
								</header>
								<div className={styles.body} data-cy="content">
									<nav className={styles.navigation} data-cy="navigation">
										<NavLink activeClassName={styles.active} to="/change-password">
											<FormattedMessage {...navMessages.changePassword} />
										</NavLink>
										{has2FA && (
											<NavLink
												activeClassName={styles.active}
												to="/second-factor-authentication"
											>
												<FormattedMessage
													{...navMessages.secondFactorAuthentication}
												/>
											</NavLink>
										)}
										{hasConnectedDevices && (
											<NavLink
												activeClassName={styles.active}
												to="/connected-devices"
											>
												<FormattedMessage {...navMessages.connectedDevices} />
											</NavLink>
										)}
										{hasSocialLogin && (
											<NavLink
												activeClassName={styles.active}
												to="/login-permissions"
											>
												<FormattedMessage
													{...navMessages.socialLoginPermissions}
												/>
											</NavLink>
										)}
									</nav>
									<main className={styles.main}>
										<Switch>
											<Route exact path="/">
												<Redirect to={HOME_PAGE} />
											</Route>
											<Route exact path="/login">
												<Login setIsLoggedIn={setIsLoggedIn} />
											</Route>
											<Route path="/change-password">
												<ChangePassword token={token} isLoggedIn={isLoggedIn} />
											</Route>
											{has2FA && (
												<Route path="/second-factor-authentication">
													<SecondFactorAuthSettings
														isLoggedIn={isLoggedIn}
													/>
												</Route>
											)}
											{hasConnectedDevices && (
												<Route path="/connected-devices">
													<ConnectedDevices />
												</Route>
											)}
											{hasSocialLogin && (
												<Route path="/login-permissions">
													<SocialLoginPermissions />
												</Route>
											)}
										</Switch>
									</main>
								</div>
							</div>
						</Router>
				)}
			</TwoFaContext.Provider>
		</IntlProvider>
	);
}

// // Uncomment to enable mocks on local env (found in service-worker.js file)
// if (process.env.NODE_ENV === 'development' && 'serviceWorker' in navigator) {
// 	navigator.serviceWorker.register(`/service-worker.js?v=${Date.now()}`);
// }
