import styles from './Note.module.scss';

interface Props {
	children: any;
}

const Note = ({ children }: Props) => {
	return (
		<div className={styles.container}>
			<div className={styles.icon}>
				<span>i</span>
			</div>
			<div className={styles.text}>{children}</div>
		</div>
	);
};

export default Note;
