import { AxiosRequestConfig } from 'axios';
import TwoFaType from '../../enums/TwoFAType';

export enum TwoFaActionType {
	SET_2FA_DATA = 'SET_2FA_DATA',
	RESET_2FA_DATA = 'RESET_2FA_DATA',
	SET_2FA_ERROR_CODE = 'SET_2FA_ERROR_CODE',
}

export enum TwoFaCode {
	CANCEL = '2FA_CANCEL',
	PHONE_ERROR = '2FA_4',
	FIRST_TIME_INIT_NEW_TYPE = '2FA_11',
	INIT_NEW_TYPE = '2FA_10',
	TWO_FA_DUO_PUSH_WAITING = 'AP_13',
	TWO_FA_PUSH_ME_WAITING = 'AP_20',
	TWO_FA_WAITING = '2FA_22',
	TWO_FA_EMPTY = 'AP_13',
	TWO_FA_INCORRECT = 'AP_6',
	TWO_FA_REQUIRED = 'AP_5',
	LOGIN_FAILED = 'AP_2',
	TEMPORARY_BANNED = 'AP_18',
	ERROR = '2FA_2',
	GENERAL_ERROR = 'GEN_1',
}

export type TwoFaRequest = {
	authType: TwoFaType;
	txId: string;
	twoFaCode: string;
	requestData: AxiosRequestConfig;
	errorCode: TwoFaCode;
	expDate: string;
	callEnabled?: boolean;
};

export interface TwoFaState {
	request: TwoFaRequest | null;
}
