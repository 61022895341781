import { ChangeEvent, FormEvent, useCallback, useMemo } from 'react';
import { useState, useEffect } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import FormMessage, { FormMessageType } from '../../components/FormMessage/FormMessage';
import Input from '../../components/Input/Input';
import Button, { ButtonType } from '../../components/Button/Button';
import config from '../../config';
import useAxios from '../../hooks/useAxios2FA';
import useScrollToTop from '../../hooks/useScrollToTop/useScrollToTop';
import useAuthErrorMessage from '../../hooks/useAuthErrorMessage';
import useManualSessionCheck from '../../hooks/useManualSessionCheck';
import endpoint from '../../endpoint';
import { getIsHiddenError } from '../../helpers/helpers';
import { RemoteError } from '../../types/Remote';

const messages = defineMessages({
	heading: {
		id: 'changePassword.heading',
		defaultMessage: 'Change password'
	},
	changePassword: {
		id: 'changePassword.changePassword',
		defaultMessage: 'Change password'
	},
	currentPassword: {
		id: 'changePassword.currentPassword',
		defaultMessage: 'Current password'
	},
	newPassword: {
		id: 'changePassword.newPassword',
		defaultMessage: 'New password'
	},
	newPasswordConfirm: {
		id: 'changePassword.newPasswordConfirm',
		defaultMessage: 'Confirm new password'
	},
	passwordChangedSuccessfully: {
		id: 'changePassword.passwordChangedSuccessfully',
		defaultMessage: 'Password changed successfully.'
	},
	enterValidPassword: {
		id: 'changePassword.enterValidPassword',
		defaultMessage: 'Please enter a valid password'
	},
	passwordsDoNotMatch: {
		id: 'changePassword.passwordsDoNotMatch',
		defaultMessage: 'Passwords do not match'
	}
});

interface FormErrors {
	[key: string]: string;
	currentPassword: string;
	newPassword: string;
	newPasswordConfirm: string;
}

interface FormValues {
	currentPassword: string;
	newPassword: string;
	newPasswordConfirm: string;
}

interface RequestBody {
	newPassword: string;
	oldPassword: string;
}

interface Props {
	isLoggedIn: boolean;
	token?: string;
}

const initialFormState = {
	currentPassword: '',
	newPassword: '',
	newPasswordConfirm: ''
};

const ChangePassword = ({ isLoggedIn }: Props) => {
	const { formatMessage } = useIntl();
	const [formValues, setFormValues] = useState<FormValues>(initialFormState);
	const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
	const [errorResponse, setErrorResponse] = useState<RemoteError>();
	const errorMessage = useAuthErrorMessage(errorResponse);
	const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
	useScrollToTop([errorResponse]);
	const { check: manualSessionCheck } = useManualSessionCheck();

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setFormValues((prevState: FormValues) => ({
			...prevState,
			[event.target.name]: event.target.value
		}));
	};

	const onSuccess = useCallback(() => {
		if (config.PASSWORD_CHANGE_SUCCESS_URI) {
			window.location.href = config.PASSWORD_CHANGE_SUCCESS_URI;
		} else {
			setErrorResponse(undefined);
			setIsSuccessMessageVisible(true);
		}
	}, []);
	
	const onFailure = useCallback((error) => {
		if (!getIsHiddenError(error)) {
			setIsSuccessMessageVisible(false);
			setErrorResponse(error.response?.data);
		}
	}, [])

	const twoFaScope = useMemo(() => ({ onSuccess, onFailure }), [onSuccess, onFailure]);

	const axios = useAxios(twoFaScope);

	useEffect(() => {
		(async () => {
			if (!(await manualSessionCheck())) return;
		})();
	}, [manualSessionCheck]);

	const validateForm = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setFormErrors(null);
		let foundErrors: FormErrors = {
			currentPassword: '',
			newPassword: '',
			newPasswordConfirm: ''
		};
		let isValid = true;
		if (!formValues?.currentPassword) {
			foundErrors.currentPassword = formatMessage(messages.enterValidPassword);
			isValid = false;
		}
		if (!formValues?.newPassword) {
			foundErrors.newPassword = formatMessage(messages.enterValidPassword);
			isValid = false;
		}
		if (formValues?.newPassword !== formValues?.newPasswordConfirm) {
			foundErrors.newPasswordConfirm = formatMessage(messages.passwordsDoNotMatch);
			isValid = false;
		}

		setFormErrors(foundErrors);
		return isValid;
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		axios
			.put(endpoint.passwordChange, {
				newPassword: formValues.newPassword,
				oldPassword: formValues.currentPassword
			});
	};

	if (!isLoggedIn) {
		return <Redirect to="/login" />;
	}

	return (
		<>
			{isSuccessMessageVisible && (
				<FormMessage type={FormMessageType.SUCCESS}>
					<FormattedMessage {...messages.passwordChangedSuccessfully} />
				</FormMessage>
			)}
			<h2>
				<FormattedMessage {...messages.heading} />
			</h2>
			<form
				onSubmit={async (e) => {
					e.preventDefault();
					if (!(await manualSessionCheck())) return;
					if (validateForm(e)) {
						handleSubmit(e);
					}
				}}
			>
				{errorMessage && (
					<FormMessage type={FormMessageType.ERROR}>{errorMessage}</FormMessage>
				)}
				<Input
					label={formatMessage(messages.currentPassword)}
					name="currentPassword"
					onChange={handleInputChange}
					type="password"
					value={formValues.currentPassword}
					autoComplete="current-password"
					error={formErrors?.currentPassword}
				/>
				<Input
					autoComplete="new-password"
					label={formatMessage(messages.newPassword)}
					name="newPassword"
					onChange={handleInputChange}
					type="password"
					value={formValues.newPassword}
					error={formErrors?.newPassword}
				/>
				<Input
					autoComplete="new-password"
					label={formatMessage(messages.newPasswordConfirm)}
					name="newPasswordConfirm"
					onChange={handleInputChange}
					type="password"
					value={formValues.newPasswordConfirm}
					error={formErrors?.newPasswordConfirm}
				/>
				<Button type={ButtonType.SUBMIT}>
					<FormattedMessage {...messages.changePassword} />
				</Button>
			</form>
		</>
	);
};

export default ChangePassword;
