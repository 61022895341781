import React from 'react';
import classNames from 'classnames';
import { MessageDescriptor, useIntl } from 'react-intl';
import styles from './Toggle.module.scss';

interface ToggleProps {
	children?: React.ReactNode;
	disabled?: boolean;
	id?: string;
	isTextualSlider?: boolean;
	label?: MessageDescriptor;
	labelClassName?: string;
	messageClassName?: string;
	name: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	value: boolean;
	wrapperClassName?: string;
}

export const Toggle = ({
	children,
	disabled,
	isTextualSlider,
	label,
	labelClassName,
	value,
	wrapperClassName,
	messageClassName,
	...props
}: ToggleProps) => {
	const intl = useIntl();
	return (
		<div className={wrapperClassName}>
			<label
				className={classNames(
					styles.container,
					{ [styles.disabled]: disabled },
					labelClassName
				)}
			>
				<span className={classNames(styles.label, messageClassName)}>
					{children || (label ? intl.formatMessage(label) : null)}
				</span>
				<div className={styles.toggle}>
					<input
						{...props}
						disabled={disabled}
						type="checkbox"
						checked={value}
						className={styles.input}
					/>
					{!isTextualSlider ? (
						<span
							className={classNames(styles.slider, {
								[styles.disabled]: disabled,
								[styles.textual]: isTextualSlider
							})}
						/>
					) : (
						<span className={styles.textSlider}>{value ? 'On' : 'Off'}</span>
					)}
				</div>
			</label>
		</div>
	);
};

export default Toggle;
