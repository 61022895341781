import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import TwoFAResponseKeys from '../enums/TwoFAResponseKeys';
import { TwoFaCode } from '../redux/TwoFaSlice/TwoFaTypes';

export const extractMessageParemeters = (messageParameters) =>
	messageParameters
		? messageParameters.reduce((acc, param) => {
				acc[param.key] = param.value;
				return acc;
		  }, {})
		: {};

export const zeroPad = (digit, places = 2) => {
	const zerosCount = places - digit.toString().length + 1;
	const leadingZeros = new Array(+(zerosCount > 0 && zerosCount)).join('0');
	return `${leadingZeros}${digit}`;
};

export const getAccessToken = () => {
	const cookie = Cookies.get('ACCESS_TOKEN');
	const token = cookie ? jwtDecode(cookie) : null;
	return token;
};

export const makeDateUTC = (date) => {
	// Our backend misses the "Z" at the end of the date, so JS doesn't know this is time in UTC
	if (date[date.length - 1] !== 'Z') {
		date += 'Z';
	}
	return date;
};

export const getIsHiddenError = (error) => {
	const key = error?.response?.data?.key;
	const errorMessage = error?.response?.data?.error;
	if (key === TwoFAResponseKeys.TWO_FA_CANCELLED_BY_USER || errorMessage === 'unauthorized') {
		return true;
	}
	
	const errorCode = error?.response?.data?.errorCode;
	if ([
		TwoFaCode.TWO_FA_REQUIRED,
		TwoFaCode.TWO_FA_PUSH_ME_WAITING,
		TwoFaCode.TWO_FA_DUO_PUSH_WAITING,
		TwoFaCode.TWO_FA_WAITING,
	].includes(errorCode)) {
		return true;
	}

	return false;
};
