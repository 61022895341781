import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import useAxios from '../../hooks/useAxios2FA';
import { getIsHiddenError } from '../../helpers/helpers';
import useScrollToTop from '../../hooks/useScrollToTop/useScrollToTop';
import useManualSessionCheck from '../../hooks/useManualSessionCheck';
import Button, { ButtonStyle, ButtonType } from '../../components/Button/Button';
import styles from './ConnectedDevices.module.scss';
import endpoint from '../../endpoint';
import FormMessage, { FormMessageType } from '../../components/FormMessage/FormMessage';
import Note from '../../components/Note/Note';
import Spinner from '../../components/Spinner/Spinner';
import { useClientConfig } from '../../helpers/themeHelpers';
import Modal from '../../components/Modal/Modal';
import useAuthErrorMessage from '../../hooks/useAuthErrorMessage';
import Error from '../../interfaces/Error';
import Response from '../../interfaces/Response';
import { RemoteError } from '../../types/Remote';
import axiosInstance from '../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'security.connectedDevices_title',
		defaultMessage: 'Connected devices'
	},
	deviceList: {
		id: 'security.connectedDevices_deviceList',
		defaultMessage: 'Device list'
	},
	howItWorksTitle: {
		id: 'security.connectedDevices_howItWorks_title',
		defaultMessage: 'How do Connected devices work?'
	},
	howItWorksBody: {
		id: 'security.connectedDevices_howItWorks_body',
		defaultMessage:
			'Every device where you have the {client} app installed and logged into your account will show up on this list. If your device gets lost or stolen, you can easily disable access to your account and keep your assets safe.'
	},
	howItWorksNote: {
		id: 'security.connectedDevices_howItWorks_note',
		defaultMessage:
			'<b>NOTE:</b> This is the list of your devices that have the {client} app installed. If you are using {client} on a browser, the browser’s device will not be displayed on this list.'
	},
	active: {
		id: 'base.active',
		defaultMessage: 'Active'
	},
	notActive: {
		id: 'base.notActive',
		defaultMessage: 'Not active'
	},
	removeDevice: {
		id: 'security.connectedDevices_removeDevice',
		defaultMessage: 'Remove device'
	},
	removeDeviceConfirmationMessage: {
		id: 'security.connectedDevices_removeDeviceConfirmationMessage',
		defaultMessage: 'Are you sure you want to remove this device?'
	},
	cancel: {
		id: 'base.cancel',
		defaultMessage: 'Cancel'
	},
	defaultErrorMessage: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.'
	},
	removeDeviceConfirmPopupHeading: {
		id: 'security.connectedDevices_removeDeviceConfirm_heading',
		defaultMessage: 'Do you wish to remove the {deviceName} from your connected devices?'
	},
	unableToRemoveLastDevicePopupBody: {
		id: 'security.connectedDevices_unableToRemoveDevice_body',
		defaultMessage:
			'To disable this device please enable another device for 2fa or choose a different 2fa method'
	},
	emptyList: {
		id: 'security.connectedDevices_noDevices',
		defaultMessage:
			'To have this function enabled please install our app and set up your second factor authentication to {client} App 2FA'
	},
	lastActiveDeviceCannotBeRemoved: {
		id: 'security.connectedDevices_unableToRemoveLastDevice',
		defaultMessage: 'Last active device can not be removed'
	}
});

type Device = {
	appId: string;
	created: string;
	deviceId: string;
	deviceName: string;
	guid: string;
	revokeDate: string;
	isActive?: boolean;
};

type PushmeConnection = {
	appId: string;
	deviceGuid: string;
	deviceId: string;
	platform: string;
};

const ConnectedDevices = () => {
	const [deviceList, setDeviceList] = useState<Device[]>([]);
	const [deviceToConfirmForRemoval, setDeviceToConfirmForRemoval] = useState<Device | null>(null);
	const [isDeviceRemoveConfirmed, setIsDeviceRemoveConfirmed] = useState(false);
	const [deviceToRemove, setDeviceToRemove] = useState<Device | null>(null);
	const [isDeviceListLoading, setIsDeviceListLoading] = useState(true);
	const [error, setError] = useState<Response<RemoteError> | null>(null);
	const errorMessage = useAuthErrorMessage(error);
	const [isListInitialized, setIsListInitialized] = useState(false);
	const [isUnableToRemoveDevicePopupVisible, setIsUnableToRemoveDevicePopupVisible] =
		useState(false);
	const { clientName } = useClientConfig();
	useScrollToTop([!!error]);

	const fetchDevices = async () => {
		if (!(await manualSessionCheck())) return;
		try {
			const { data: allDevices } = await axiosInstance.get<Device[]>(endpoint.devices);
			const { data: pushMeConnections } = await axiosInstance.get<PushmeConnection[]>(endpoint.pushMeConnections);

			pushMeConnections.forEach((connection: PushmeConnection) => {
				const connectionMatchIndex = allDevices.findIndex(
					({ guid }) => guid === connection.deviceGuid
				);
				if (connectionMatchIndex >= 0) {
					allDevices[connectionMatchIndex].isActive = true;
				}
			});

			setIsListInitialized(true);
			setDeviceList(allDevices);
			setIsDeviceListLoading(false);
		} catch (e: any) {
			if (!getIsHiddenError(e)) {
				setIsListInitialized(true);
				setDeviceList([]);
				setError(e.response);
				setIsDeviceListLoading(false);
			}
		}
	};
	const { check: manualSessionCheck } = useManualSessionCheck();

	const requestDeviceRemoval = async (device: Device) => {
		if (!(await manualSessionCheck())) return;
		if (!device.isActive) {
			setDeviceToRemove(device);
			setDeviceToConfirmForRemoval(device);
		} else if (deviceList.filter((device) => device.isActive).length <= 1) {
			setIsUnableToRemoveDevicePopupVisible(true);
		} else {
			setDeviceToRemove(device);
			setDeviceToConfirmForRemoval(device);
		}
	};

	useEffect(() => {
		fetchDevices();
	}, []);

	const onSuccess = useCallback(() => {
		setError(null);
		setIsDeviceListLoading(true);
		fetchDevices();
	}, [])

	const onFailure = useCallback((error) => {
		if (!getIsHiddenError(error)) {
			setError(error.response);
		}
		fetchDevices();
	}, []);

	const twoFaScope = useMemo(() => ({ onSuccess, onFailure }), [onSuccess, onFailure]);

	const axios = useAxios(twoFaScope);

	useEffect(() => {
		if (deviceToRemove && isDeviceRemoveConfirmed) {
			setDeviceToRemove(null);
			setDeviceToConfirmForRemoval(null);
			setIsDeviceRemoveConfirmed(false);
			axios.delete(`${endpoint.devices}/${deviceToRemove.guid}`);
		}
	}, [deviceToRemove, isDeviceRemoveConfirmed, axios]);

	return (
		<div>
			<h2>
				<FormattedMessage {...messages.title} />
			</h2>
			{error && (
				<FormMessage type={FormMessageType.ERROR} className={styles.formError}>
					{errorMessage}
				</FormMessage>
			)}
			<div>
				<div className={styles.tableContainer}>
					{isDeviceListLoading && !isListInitialized ? (
						<div className={styles.loaderContainer}>
							<Spinner />
						</div>
					) : deviceList.length ? (
						<>
							<p>
								<FormattedMessage {...messages.deviceList} />
							</p>
							<table className={styles.deviceTable}>
								<thead>
									<tr>
										<th>DEVICE</th>
										<th>2FA</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{deviceList.map((device: Device) => {
										return (
											<DeviceListItemForTable
												key={device.guid}
												name={device.deviceName}
												isActive={device.isActive || false}
												canBeRemoved={
													(device.isActive &&
														deviceList.filter(
															(device) => device.isActive
														).length > 1) ||
													!device.isActive
												}
												onRemoveClick={() => {
													requestDeviceRemoval(device);
												}}
											/>
										);
									})}
								</tbody>
							</table>
							<div className={styles.deviceList}>
								{deviceList.map((device: Device) => {
									return (
										<DeviceListItemForList
											key={device.guid}
											name={device.deviceName}
											isActive={device.isActive || false}
											canBeRemoved={
												(device.isActive &&
													deviceList.filter((device) => device.isActive)
														.length > 1) ||
												!device.isActive
											}
											onRemoveClick={() => {
												requestDeviceRemoval(device);
											}}
										/>
									);
								})}
							</div>
						</>
					) : (
						<div className={styles.emptyDeviceListMessageContainer}>
							<FormattedMessage
								{...messages.emptyList}
								values={{
									client: <>{clientName}</>
								}}
							/>
						</div>
					)}
				</div>
				<div>
					<h3 className={classNames(styles.secondaryTitle, styles.lighter)}>
						<FormattedMessage {...messages.howItWorksTitle} />
					</h3>
					<p>
						<FormattedMessage
							{...messages.howItWorksBody}
							values={{
								client: <>{clientName}</>
							}}
						/>
					</p>
				</div>
				<Note>
					<FormattedMessage
						{...messages.howItWorksNote}
						values={{
							b: (text: string) => <b>{text}</b>,
							client: <>{clientName}</>
						}}
					/>
				</Note>
			</div>
			{deviceToConfirmForRemoval && (
				<DeviceRemovalConfirmationModal
					setIsDeviceRemoveConfirmed={setIsDeviceRemoveConfirmed}
					setDeviceToConfirmForRemoval={setDeviceToConfirmForRemoval}
				/>
			)}
		</div>
	);
};

const DeviceRemovalConfirmationModal = ({
	setIsDeviceRemoveConfirmed,
	setDeviceToConfirmForRemoval
}: {
	setIsDeviceRemoveConfirmed: (value: boolean) => void;
	setDeviceToConfirmForRemoval: (value: Device | null) => void;
}) => {
	const { doubleCheckImage } = useClientConfig();

	return (
		<Modal className={styles.confirmationModalContent}>
			<img
				src={doubleCheckImage.src}
				alt={doubleCheckImage.alt}
				className={styles.confirmationImage}
			/>
			<p className={styles.confirmationTitle}>
				<FormattedMessage {...messages.removeDeviceConfirmationMessage} />
			</p>
			<Button
				type={ButtonType.SUBMIT}
				onClick={() => setIsDeviceRemoveConfirmed(true)}
				className={styles.confirmButton}
			>
				<FormattedMessage {...messages.removeDevice} />
			</Button>
			<Button
				type={ButtonType.SUBMIT}
				buttonStyle={ButtonStyle.SECONDARY}
				onClick={() => setDeviceToConfirmForRemoval(null)}
				className={styles.cancelButton}
			>
				<FormattedMessage {...messages.cancel} />
			</Button>
		</Modal>
	);
};

const DeviceListItemForTable = ({
	name,
	isActive,
	onRemoveClick,
	canBeRemoved
}: {
	name: string;
	isActive: boolean;
	canBeRemoved: boolean;
	onRemoveClick: () => void;
}) => {
	const { formatMessage } = useIntl();
	return (
		<tr>
			<td>{name}</td>
			<td>
				{isActive ? (
					<span className={classNames(styles.twoFA, styles.active)}>
						<FormattedMessage {...messages.active} />
					</span>
				) : (
					<span className={classNames(styles.twoFA, styles.inactive)}>
						<FormattedMessage {...messages.notActive} />
					</span>
				)}
			</td>
			<td>
				<Button
					title={
						!canBeRemoved ? formatMessage(messages.lastActiveDeviceCannotBeRemoved) : ''
					}
					disabled={!canBeRemoved}
					buttonStyle={ButtonStyle.LINK}
					type={ButtonType.BUTTON}
					className={styles.removeButton}
					onClick={onRemoveClick}
				>
					<FormattedMessage {...messages.removeDevice} />
				</Button>
			</td>
		</tr>
	);
};

const DeviceListItemForList = ({
	name,
	isActive,
	onRemoveClick,
	canBeRemoved
}: {
	name: string;
	isActive: boolean;
	canBeRemoved: boolean;
	onRemoveClick: () => void;
}) => {
	const { formatMessage } = useIntl();
	return (
		<div className={styles.deviceListItemForList}>
			<div className={styles.nameContainer}>
				<div className={styles.name}>{name}</div>
				<div>
					{isActive ? (
						<span className={classNames(styles.twoFA, styles.active)}>
							<FormattedMessage {...messages.active} />
						</span>
					) : (
						<span className={classNames(styles.twoFA, styles.inactive)}>
							<FormattedMessage {...messages.notActive} />
						</span>
					)}
				</div>
			</div>
			<div>
				<Button
					title={
						!canBeRemoved ? formatMessage(messages.lastActiveDeviceCannotBeRemoved) : ''
					}
					disabled={!canBeRemoved}
					buttonStyle={ButtonStyle.DANGER}
					type={ButtonType.BUTTON}
					className={styles.removeButton}
					onClick={onRemoveClick}
				>
					<FormattedMessage {...messages.removeDevice} />
				</Button>
			</div>
		</div>
	);
};

export default ConnectedDevices;
