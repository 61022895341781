import { useEffect } from 'react';
import { UserManager } from 'oidc-client-ts';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import FormMessage, { FormMessageType } from '../../components/FormMessage/FormMessage';
import Spinner from '../../components/Spinner/Spinner';
import { getAccessToken } from '../../helpers/helpers';
import useScrollToTop from '../../hooks/useScrollToTop/useScrollToTop';
import { HOME_PAGE } from '../../App';
import config from '../../config';

const messages = defineMessages({
	defaultErrorMessage: {
		id: 'error.unexpected_error',
		defaultMessage: 'Unexpected error. Please contact support.'
	}
});

const hasCodeInUrl = (location) => {
	const searchParams = new URLSearchParams(location.search);
	return Boolean(searchParams.get('code'));
};

const userManager = new UserManager({
	authority: config.AUTHORITY,
	client_id: config.CLIENT_ID,
	redirect_uri: config.REDIRECT_URI,
	scope: config.SCOPE,
	metadata: {
		issuer: config.ISSUER,
		token_endpoint: config.TOKEN_ENDPOINT,
		authorization_endpoint: config.AUTHORIZATION_ENDPOINT
	},
	loadUserInfo: false,
	response_type: 'code'
});

const REQUIRED_CONFIGURATION_OPTIONS = [
	'AUTHORITY',
	'AUTHORIZATION_ENDPOINT',
	'CLIENT_ID',
	'ISSUER',
	'REDIRECT_URI',
	'SCOPE',
	'TOKEN_ENDPOINT'
];

const Login = ({ setIsLoggedIn }) => {
	let searchParams = new URL(document.location).searchParams;
	const history = useHistory();
	const location = useLocation();
	const error = location.state?.error || searchParams.get('error');
	useScrollToTop([error]);

	const missingConfigs = REQUIRED_CONFIGURATION_OPTIONS.reduce((acc, name) => {
		if (!config[name]) acc.push(name);
		return acc;
	}, []);

	useEffect(() => {
		if (error || missingConfigs.length) return;
		if (!hasCodeInUrl(location)) {
			// Go get code from auth server login page
			userManager.signinRedirect();
		} else {
			// If we have code in url, exchange it for token on "token" endpoint
			userManager
				.signinCallback()
				.then((user) => {
					if (user) {
						setIsLoggedIn(true);
						history.push(HOME_PAGE);
					} else {
						throw new Error('No user data returned from endpoint');
					}
				})
				.catch((e) => {
					// Theres some issue, possibly with response
					// The lib fails after getting response from /token endpoint
					// For now, check token and if it's fine, swallow the error
					// Possibly the error has to do with incorrect encryption keys set up or similar
					if (getAccessToken()?.user_id) {
						setIsLoggedIn(true);
						history.push(HOME_PAGE);
						return;
					}
					history.push('/login', { error: JSON.stringify(e) });
				});
		}
	}, [error, history, location, missingConfigs.length]);

	if (missingConfigs.length) {
		return (
			<FormMessage type={FormMessageType.ERROR}>
				Missing configuration properties:{' '}
				<ul>
					{missingConfigs.map((config) => (
						<li key={config}>{config}</li>
					))}
				</ul>
			</FormMessage>
		);
	}

	if (error) {
		return (
			<FormMessage type={FormMessageType.ERROR}>
				<FormattedMessage {...messages.defaultErrorMessage} />
			</FormMessage>
		);
	}
	return <Spinner />;
};

export default Login;
