import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { AxiosError, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import useInputAttempts from './useInputAttempts';
import { RemoteError } from '../types/Remote';
import { useCountdown } from './useCountdown';
import axiosInstance from '../helpers/axiosInstance';
import TwoFaType from '../enums/TwoFAType';
import { TwoFaCode, TwoFaRequest } from '../redux/TwoFaSlice/TwoFaTypes';
import { useRemoteShared } from './useRemote';

const messages = defineMessages({
	tooShort: {
		id: 'twoFABox.tooShort',
		defaultMessage: 'Code is too short',
	},
	cannotBeEmpty: {
		id: 'inputErrors.cannotBeEmpty',
		defaultMessage: 'This field cannot be empty',
	},
	temporaryBanned: {
		id: 'twoFABox.termporaryBanned',
		defaultMessage: 'User temporary banned',
	},
	wrongCode: {
		id: 'authConfirm.wrongCode',
		defaultMessage: 'Wrong 2FA code',
	},
});

type Props = {
	config: TwoFaRequest;
	updateConfig?: (error: RemoteError) => void;
	onSuccess?: (data: AxiosResponse) => void;
	onFailure?: (error: AxiosError<RemoteError>) => void;
	onErrorCodeChange: (code: TwoFaCode) => void;
	onBeforeSend?: (config: AxiosRequestConfig) => AxiosRequestConfig;
};

const useTwoFaProcessor = ({
	config: { errorCode, authType, txId, twoFaCode, requestData, expDate },
	updateConfig,
	onSuccess,
	onFailure,
	onErrorCodeChange,
	onBeforeSend,
}: Props) => {
	const timer = useRef<number>();
	const { formatMessage } = useIntl();
	const [code, setCode] = useState<string>('');
	const [inputError, setInputError] = useState<string | null>(null);
	const [attempt, beginAttempt, fail, expire, endAttempt] = useInputAttempts();
	const retryCount = 3;
	const [isResubmitRequired, setIsResubmitRequired] = useState(false);
	const { isExpired, formattedTimeLeft: timeRemaining, timeLeft } = useCountdown(expDate || '0');
	const isExpiring = timeLeft! < 5000;

	const ref = useRef<HTMLFormElement>(null);

	const handleSuccess = useCallback((response: AxiosResponse) => {
		onSuccess?.call(this, response);
		return response;
	}, [onSuccess]);

	const handleError = useCallback(async (error: AxiosError<any>) => {
		const { errorCode: errCode } = error.response!.data;
		if (
			[
				TwoFaCode.TWO_FA_PUSH_ME_WAITING,
				TwoFaCode.TWO_FA_DUO_PUSH_WAITING,
				TwoFaCode.TWO_FA_WAITING,
			].includes(errCode)
		) {
			return onErrorCodeChange(errCode);
		}
		if (
			[
				TwoFaCode.TWO_FA_EMPTY,
				TwoFaCode.TWO_FA_INCORRECT,
				TwoFaCode.ERROR,
			].includes(errCode)
		) {
			if (
				authType &&
				[TwoFaType.DUO_PUSH, TwoFaType.PUSH_ME].includes(authType)
			) {
				return onFailure?.call(this, error);
			}
			fail();
			return setInputError(formatMessage(messages.wrongCode));
		}
		if (errCode === TwoFaCode.TEMPORARY_BANNED) {
			if (
				authType &&
				[TwoFaType.DUO_PUSH, TwoFaType.PUSH_ME].includes(authType)
			) {
				return onFailure?.call(this, error);
			}
			return setInputError(formatMessage(messages.temporaryBanned));
		}
		if (errCode === TwoFaCode.TWO_FA_REQUIRED) {
			if (updateConfig) {
				updateConfig(error.response!.data);
			}

			beginAttempt();
			return null;
		}
		return onFailure?.call(this, error);
	}, [authType, beginAttempt, fail, formatMessage, onErrorCodeChange, onFailure, updateConfig]);

	const submitHandler = useCallback(
		(headers: RawAxiosRequestHeaders) => {
			const updatedConfig = {
				...requestData, 				
				data: typeof requestData?.data === 'string'
					? JSON.parse(requestData?.data)
					: requestData?.data,
				headers: {
					...requestData.headers, 
					...headers
				}
				};

			return axiosInstance(onBeforeSend ? onBeforeSend(updatedConfig) : updatedConfig)
				.then(handleSuccess)
				.catch(handleError);
		},
		[handleError, handleSuccess, onBeforeSend, requestData]
	);

	const [actionStatus, doAction] = useRemoteShared();

	const onFallback = useCallback((initiateNewTx: boolean, type: 'sms' | 'call') => {
		const txId$ = initiateNewTx
			?  axiosInstance({...requestData, skip2FA: true })
				.then(handleSuccess)
				.catch((e: AxiosError<RemoteError>) => {
					const txId = e.response?.data?.messageParameters?.find(x => x.key === 'tx_id')?.value;
					// eslint-disable-next-line @typescript-eslint/no-throw-literal
					if (!txId) throw e;
					return { txId, type: e.response?.data?.messageParameters?.find(x => x.key === '2fa_type')?.value};
				})
			: Promise.resolve({txId, type: authType });

		return doAction(txId$.then(({ txId, type: currentTwoFaType }: any) => submitHandler({
			'2fa-fallback': currentTwoFaType === TwoFaType.PUSH_ME,
			'tx-id': txId || '', 
			'phone-notification-type': type
		})));
	}, [doAction, handleSuccess, requestData, submitHandler, txId, authType]);

	const onSubmit = useCallback(
		async (initiateNewTx?: boolean) => {
			
			if (!code && !initiateNewTx) {
				fail();
				return setInputError(formatMessage(messages.cannotBeEmpty));
			}
			if (code && code.length < 6 && !initiateNewTx) {
				fail();
				return setInputError(formatMessage(messages.tooShort));
			}

			return doAction(submitHandler(initiateNewTx 
				? {} 
				: {
					'2fa': code,
					'tx-id': txId,
				}
			));
		},
		[code, doAction, submitHandler, txId, fail, formatMessage]
	);

	const onCodeChange = useCallback((value: string) => {
		setInputError(null);
		const reg = /^[0-9]*$/;
		if (reg.test(value) && value.length <= 6) setCode(value);
	}, []);

	useEffect(() => {
		if (isExpiring) return () => clearInterval(timer.current);
		if (
			(authType === TwoFaType.DUO_PUSH || authType === TwoFaType.PUSH_ME) &&
			(errorCode === TwoFaCode.TWO_FA_REQUIRED ||
				errorCode === TwoFaCode.TWO_FA_PUSH_ME_WAITING ||
				errorCode === TwoFaCode.TWO_FA_DUO_PUSH_WAITING ||
				errorCode === TwoFaCode.TWO_FA_WAITING)
		) {
			timer.current = window.setInterval(() => {
				void submitHandler({
					'2fa': twoFaCode,
					'tx-id': txId,
				});
			}, 5000);
		}

		return () => clearInterval(timer.current);
	}, [authType, errorCode, isExpiring, requestData, submitHandler, twoFaCode, txId]);

	useEffect(() => {
		if (isExpired) {
			expire();
			endAttempt();
		}
	}, [isExpired, expire, endAttempt]);

	useEffect(() => {
		if (attempt > retryCount) {
			setIsResubmitRequired(true);
		}
	}, [attempt, retryCount]);

	useEffect(() => {
		beginAttempt();
		ref.current?.scrollIntoView({
			block: 'center',
			inline: 'center',
		});
	}, []);

	return {
		ref,
		code,
		isResubmitRequired,
		actionStatus: actionStatus.status,
		inputError,
		onCodeChange,
		onSubmit,
		onFallback,
		timeRemaining,
		isExpired,
	};
};

export default useTwoFaProcessor;
