enum PasswordChangeValidationErrorCode {
	'A_8' = 'A_8',
	'A_9' = 'A_9',
	'A_10' = 'A_10',
	'A_36' = 'A_36',
	'A_38' = 'A_38',
	'A_44' = 'A_44'
}

export default PasswordChangeValidationErrorCode;
