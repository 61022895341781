import { useMemo } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { TwoFaConsumerScope } from '../contexts/TwoFaContext';
import { config, responseInterceptors, requestInterceptor } from '../helpers/axiosInstance';
import { useRegisterTwoFaConsumer } from './useTwoFa';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (scope: TwoFaConsumerScope) {
	const [consumerId, twoFaConsumer] = useRegisterTwoFaConsumer(scope);

	return useMemo(() => {
		const instance = axios.create({ ...config, twoFaConsumerId: consumerId });
		instance.interceptors.request.use(...requestInterceptor);
		instance.interceptors.response.use(...responseInterceptors);
		instance.interceptors.response.use(
			(x: AxiosResponse) => {
				if (twoFaConsumer.onSuccess) twoFaConsumer.onSuccess(x);
				return x;
			},
			(x: AxiosError<any>) => {
				if (twoFaConsumer.onFailure) twoFaConsumer.onFailure(x);
				else throw x;
			}
		);
		return instance;
	}, [consumerId, twoFaConsumer]);
}
