import { AxiosRequestConfig } from 'axios';
import { TwoFaCode } from '../redux/TwoFaSlice/TwoFaTypes';
import TwoFaActions from '../redux/TwoFaSlice/TwoFaActions';

const TwoFaHeaders = [
	'2fa-fallback',
	'phone-notification-type',
	'tx-id',
	'2fa',
];

// eslint-disable-next-line import/no-anonymous-default-export
export default (
	store: any,
	requestData: AxiosRequestConfig,
	authData: any,
	errorCode: TwoFaCode
) => {
	const { messageParameters } = authData;

	const { value: authType } = messageParameters.find(
		(param: { key: string; value: string }) => param.key === '2fa_type'
	);

	const { value: txId } = messageParameters.find(
		(param: { key: string }) => param.key === 'tx_id'
	);

	const { value: twoFaCode } = messageParameters.find(
		(param: { key: string; value: string }) => param.key === '2fa_code'
	);

	const { value: expDate } = messageParameters.find(
		(param: { key: string }) => param.key === 'expiration_date'
	);

	const { value: callEnabled } = messageParameters.find(
		(param: { key: string; value: string }) => param.key === 'call_enabled') ||
		{};

	const headers: Record<string, any> = { ...requestData.headers };
	TwoFaHeaders.forEach((header) => {
		if (header in headers) delete headers[header];
	});

	store.dispatch(
		TwoFaActions.setData({
			requestData: {
				data: requestData.data,
				headers: headers,
				baseURL: requestData.baseURL,
				method: requestData.method,
				params: requestData.params,
				url: requestData.url,
				twoFaConsumerId: requestData.twoFaConsumerId,
				skip2FA: requestData.skip2FA,
			},
			authType,
			txId,
			twoFaCode,
			callEnabled,
			expDate,
			errorCode,
		})
	);
};
