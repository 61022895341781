import { useLayoutEffect } from 'react';
import { ClientConfig, Theme, ThemesConfig } from '../interfaces/Theme';
import config from '../config';
import { useSelector } from 'react-redux';
import { getClientConfig } from '../redux/AppSlice/AppSelectors';

interface ThemeClientConfig {
	externalThemeConfig: ThemesConfig;
	externalClientConfig: ClientConfig;
}

export const getExternalThemeConfig = async () => {
	const theme = await import(`../client_themes/client-theme-${config.CLIENT_NAME}.json`);

	return {
		externalThemeConfig: theme.themeConfig,
		externalClientConfig: theme.clientConfig
	} as ThemeClientConfig;
};

export const useTheme = (theme: Theme): void => {
	useLayoutEffect((): void => {
		// Iterate through each value in theme object
		Object.keys(theme).map((k) =>
			// Update css variables in document's root element
			document.documentElement.style.setProperty(`--${k}`, theme[k])
		);
	}, [theme]);
};

export const useClientConfig = () => {
	return useSelector(getClientConfig);;
};

export const getPrivacyPolicyURLByClient = (
	privacyPolicy: { [key: string]: string },
	lang: string
) => privacyPolicy[lang];

export const getTermsAndConditionsURLByClient = (
	termsAndConditions: { [key: string]: string },
	lang: string
) => termsAndConditions[lang];
