import React, { useCallback, useState, useRef, useEffect, ChangeEvent } from 'react';
import QRCode from 'qrcode.react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Input from '../../../components/Input/Input';
import FormMessage, { FormMessageType } from '../../../components/FormMessage/FormMessage';
import Button, { ButtonStyle } from '../../../components/Button/Button';
import { VerifyParameters } from '../SecondFactorAuthSettings';
import TwoFAResponseKeys from '../../../enums/TwoFAResponseKeys';
import TwoFAType from '../../../enums/TwoFAType';
import useScrollToTop from '../../../hooks/useScrollToTop/useScrollToTop';
import styles from './VerifyCurrentAndNewForm.module.scss';
import { ButtonType } from '../../../components/Button/Button';
import endpoint from '../../../endpoint';
import logout from '../../../helpers/logout';
import { extractMessageParemeters } from '../../../helpers/helpers';
import { getIsHiddenError } from '../../../helpers/helpers';
import { useClientConfig } from '../../../helpers/themeHelpers';
import axiosInstance from '../../../helpers/axiosInstance';

const messages = defineMessages({
	twoFATitle: {
		id: '2ndfactorauth.title',
		defaultMessage: 'Second factor authentication'
	},
	invalidKey: {
		id: '2ndfactorauth.invalid_key',
		defaultMessage: 'You must enter valid authentication key'
	},
	incorrectKey: {
		id: '2ndfactorauth.wrong_key',
		defaultMessage: 'Incorrect authentication key. Please try again. '
	},
	key: {
		id: '2ndfactorauth.key',
		defaultMessage: 'Key'
	},
	submit: {
		id: 'base.submit',
		defaultMessage: 'Submit'
	},
	tooManyRequests: {
		id: 'scvalidationexception.1001_too_many_requests',
		defaultMessage: 'Too many requests'
	},
	txIdLabel: {
		id: '2ndfactorauth.transaction_id',
		defaultMessage: 'Session ID:'
	},
	googleAuthentication: {
		id: 'profile.2factorauth_google',
		defaultMessage: 'Google authentication'
	},
	smsAuthentication: {
		id: 'profile.2factorauth_sms',
		defaultMessage: 'SMS authentication'
	},
	emailAuthentication: {
		id: 'profile.2factorauth_email',
		defaultMessage: 'Email authentication'
	},
	googleAuthenticatorRegistration: {
		id: '2ndfactorauth.google_auth_qr',
		defaultMessage: 'Google authenticator registration'
	},
	cancel: {
		id: 'questionnaire.questionnaire_cancel',
		defaultMessage: 'Cancel'
	},
	pushMeCancelledOrExpired: {
		id: '2ndfactorauth.pushme_cancelled_or_expired',
		defaultMessage: '{client} App 2FA was cancelled or has expired'
	},
	close: {
		id: 'base.close',
		defaultMessage: 'Close'
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next'
	},
	summaryEmail: {
		id: '2ndfactorauth.summary_email',
		defaultMessage:
			'You should receive your authentication code via an email message. Enter the code to proceed further.'
	},
	summaryPushMe: {
		id: '2ndfactorauth.summary_pushme',
		defaultMessage:
			'You should receive a confirmation request in {client} application. Confirm the request to proceed further.'
	},
	summaryGoogle: {
		id: '2ndfactorauth.summary_google',
		defaultMessage: 'Google Authenticator is not an available 2FA option'
	},
	summarySMS: {
		id: '2ndfactorauth.summary_sms',
		defaultMessage:
			'You should receive your authentication code via sms message. Enter the code to proceed further.'
	}
});

export interface FormErrors {
	currentTypeCode: string;
	newTypeCode: string;
}

export interface FormValues {
	currentTypeCode: string;
	newTypeCode: string;
}

interface RequestBody {
	currentTypeCode: string;
	newTypeCode: string;
	newType?: TwoFAType;
	txId?: string;
}

interface Props {
	parameters: VerifyParameters;
	email: string;
	onSuccess: (newType: TwoFAType) => void;
	onError: (response?: any) => void;
	onCancel: () => void;
}

const initialFormState = {
	currentTypeCode: '',
	newTypeCode: ''
};

const VerifyCurrentAndNewForm = ({
	parameters: {
		currentType,
		currentTypeTwoFaCode,
		newGoogleAuthSecret,
		newType,
		newTypeTxId,
		txId
	},
	email,
	onError,
	onSuccess,
	onCancel
}: Props) => {
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [isCodeIncorrect, setIsCodeIncorrect] = useState(false);
	const [isTwoFAPolling, setIsTwoFAPolling] = useState(false);
	const [isPushMeCancelledOrExpired, setIsPushMeCancelledOrExpired] = useState(false);
	const intl = useIntl();
	const timeoutRef = useRef<number>();

	const [formValues, setFormValues] = useState<FormValues>(initialFormState);
	const { clientName } = useClientConfig();

	useScrollToTop([isCodeIncorrect]);

	const getAuthenticationSummary = useCallback((type: TwoFAType) => {
		switch (type) {
			case TwoFAType.EMAIL:
				return intl.formatMessage(messages.summaryEmail);
			case TwoFAType.PUSH_ME:
				return intl.formatMessage(messages.summaryPushMe, { client: clientName });
			case TwoFAType.GOOGLE:
				return intl.formatMessage(messages.summaryGoogle);
			case TwoFAType.SMS:
				return intl.formatMessage(messages.summarySMS);
			default:
				throw new Error('Unexpected verification type in current and new code 2fa form');
		}
	}, []);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setFormValues((prevState: FormValues) => ({
			...prevState,
			[event.target.name]: event.target.value
		}));
	};

	const handleSubmit = (values: FormValues) => {
		if (currentType !== TwoFAType.PUSH_ME) {
			setIsRequestPending(true);
		}
		const poll = () => {
			axiosInstance({
				method: 'post',
				url: endpoint.twoFATypeChangeVerify,
				data: {
					...values,
					newType,
					newTypeTxId,
					txId,
					recaptcha: undefined,
					...(currentType === TwoFAType.PUSH_ME
						? { currentTypeCode: currentTypeTwoFaCode }
						: {})
				},
				skip2FA: true
			})
				.then((response: any) => {
					setIsRequestPending(false);
					setIsCodeIncorrect(false);
					setIsTwoFAPolling(false);
					onSuccess(response?.data?.type || newType);
				})
				.catch((error: any) => {
					const key = error?.response?.data?.key;
					const messageParameters = extractMessageParemeters(
						error?.response?.data?.messageParameters
					);
					if (
						key === TwoFAResponseKeys.PUSH_ME_TWO_FA_WAITING ||
						key === TwoFAResponseKeys.TWO_FA_WAITING
					) {
						timeoutRef.current = window.setTimeout(() => {
							poll();
						}, 5000);

						return;
					}

					setIsRequestPending(false);
					if (key === '2fa_incorrect') {
						if (currentType === TwoFAType.PUSH_ME) {
							if (
								!messageParameters.type ||
								messageParameters.type === TwoFAType.PUSH_ME
							) {
								setIsPushMeCancelledOrExpired(true);
							} else {
								setIsCodeIncorrect(true);
							}
						} else {
							setIsCodeIncorrect(true);
						}
					} else {
						onError(error.response);
					}
				});
		};
		if (currentType === TwoFAType.PUSH_ME) setIsTwoFAPolling(true);
		poll();
	};

	const handleCancel = () => {
		window.clearTimeout(timeoutRef.current);
		onCancel();
	};

	useEffect(() => () => handleCancel(), []);

	const isPushMePolling = currentType === TwoFAType.PUSH_ME && isTwoFAPolling;

	return (
		<div
			className={classNames(styles.container, {
				[styles.googleAuthContainer]: newType === TwoFAType.GOOGLE
			})}
		>
			<h2 className={styles.heading}>{intl.formatMessage(messages.twoFATitle)}</h2>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					// TODO possibly validate client side here
					handleSubmit(formValues);
				}}
			>
				<div className={styles.formGrid}>
					{(isCodeIncorrect || isPushMeCancelledOrExpired) && (
						<FormMessage type={FormMessageType.ERROR} className={styles.formError}>
							{intl.formatMessage(
								isPushMeCancelledOrExpired
									? messages.pushMeCancelledOrExpired
									: messages.incorrectKey,
								{
									client: clientName
								}
							)}
						</FormMessage>
					)}

					{/* // Jei ne pushme, rodom ir labelius, ir inputus
					// Jei pushme, rodom labelius tik jei pollina */}

					{currentType !== TwoFAType.PUSH_ME && (
						<>
							<p>{getAuthenticationSummary(currentType as TwoFAType)}</p>
							<label className={styles.label}>
								<FormattedMessage {...messages.txIdLabel} />
							</label>
							<span>{txId}</span>
							<>
								<Input
									className={styles.input}
									type="text"
									disabled={isRequestPending}
									name="currentTypeCode"
									value={formValues.currentTypeCode}
									onChange={handleInputChange}
									inputMode="numeric"
								/>
							</>
						</>
					)}

					{isTwoFAPolling && !isCodeIncorrect && (
						<>
							<p>{getAuthenticationSummary(TwoFAType.PUSH_ME as TwoFAType)}</p>
							<label className={styles.label}>
								<FormattedMessage {...messages.txIdLabel} />
							</label>
							<span>{txId}</span>
						</>
					)}

					{/* New 2fa type */}
					{!isTwoFAPolling && !isCodeIncorrect && (
						<>
							<br />
							<p>{getAuthenticationSummary(newType as TwoFAType)}</p>
							{newGoogleAuthSecret && (
								<>
									<div className={styles.sectionHeading}>
										<FormattedMessage
											{...messages.googleAuthenticatorRegistration}
										/>
									</div>
									<div className={styles.qrContainer}>
										<QRCode
											size={150}
											level="M"
											value={`otpauth://totp/${email}?secret=${newGoogleAuthSecret}&issuer=${clientName}`}
										/>
										<div className={styles.googleAuthenticatorRegistrationCode}>
											{newGoogleAuthSecret}
										</div>
									</div>
								</>
							)}
							<label className={styles.label}>
								<FormattedMessage {...messages.txIdLabel} />
							</label>
							<span>{newTypeTxId}</span>
							<Input
								className={styles.input}
								type="text"
								disabled={isRequestPending}
								value={formValues.newTypeCode}
								onChange={handleInputChange}
								name="newTypeCode"
								inputMode="numeric"
							/>
							<div />
						</>
					)}
					<div className={styles.buttonContainer}>
						{!isPushMePolling && (
							<Button
								className={styles.button}
								disabled={isRequestPending}
								type={ButtonType.SUBMIT}
							>
								<FormattedMessage {...messages.next} />
							</Button>
						)}
						<Button
							buttonStyle={isPushMePolling ? ButtonStyle.PRIMARY : ButtonStyle.LINK}
							className={classNames(styles.button, styles.buttonCancel)}
							disabled={isRequestPending}
							type={ButtonType.BUTTON}
							onClick={handleCancel}
						>
							{isPushMePolling
								? intl.formatMessage(messages.close)
								: intl.formatMessage(messages.cancel)}
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default VerifyCurrentAndNewForm;
