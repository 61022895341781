import { useEffect, useState, useReducer } from 'react';
import { Method } from 'axios';
import { defineMessages, FormattedMessage } from 'react-intl';
import Toggle from '../../components/Toggle/Toggle';
import FormMessage, { FormMessageType } from '../../components/FormMessage/FormMessage';
import Spinner from '../../components/Spinner/Spinner';
import useScrollToTop from '../../hooks/useScrollToTop/useScrollToTop';
import useAuthErrorMessage from '../../hooks/useAuthErrorMessage';
import useManualSessionCheck from '../../hooks/useManualSessionCheck';
import endpoint from '../../endpoint';
import styles from './SocialLoginPermissions.module.scss';
import { getIsHiddenError } from '../../helpers/helpers';
import { useClientConfig } from '../../helpers/themeHelpers';
import axiosInstance from '../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'profile.profile_social_login_permissions',
		defaultMessage: 'Social Login Permissions'
	},
	body: {
		id: 'profile.profile_social_login_permissions_body',
		defaultMessage:
			'You can choose whether you would like to use Facebook, Google, and Apple ID as your login options at {client}. Please note that if you signed up to the website with one of these options, you must change your password on the “Account details” page prior to disabling the social logins.'
	},
	update: {
		id: 'profile.profile_social_login_permissions_title',
		defaultMessage: 'Update'
	},
	enableFacebookLogin: {
		id: 'profile.enableFbLogin',
		defaultMessage: 'Enable Facebook Login'
	},
	enableGoogleLogin: {
		id: 'profile.enableGpLogin',
		defaultMessage: 'Enable GooglePlus Login'
	},
	enableAppleIdLogin: {
		id: 'profile.enableAppleIdLogin',
		defaultMessage: 'Enable Apple ID Login'
	},
	enable2FAOnLogin: {
		id: 'labels.label_2fa_on_login',
		defaultMessage: 'Enable second factor authentication on login'
	},
	loginSettingsFail: {
		id: 'profile.exception_2fa_on_login',
		defaultMessage: 'Failed to change second factor authentication on login'
	},
	dataSuccessfullyUpdated: {
		id: 'profile.set_login_settings_success',
		defaultMessage: 'Data was successfully changed'
	},
	unexpectedError: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.'
	},
	locked: {
		id: 'profile.locked',
		defaultMessage: 'Locked'
	}
});

enum ProviderType {
	FACEBOOK = 'FACEBOOK',
	GOOGLE = 'GOOGLE',
	APPLE_ID = 'APPLE_ID'
}

enum ToggleName {
	IS_FACEBOOK_LOGIN_DISABLED = 'isFacebookLoginDisabled',
	IS_GOOGLE_LOGIN_DISABLED = 'isGoogleLoginDisabled',
	IS_APPLE_ID_LOGIN_DISABLED = 'isAppleIdLoginDisabled',
	IS_LOGIN_2FA_ENABLED = 'isLogin2FAEnabled'
}

interface SocialProvider {
	providerType: ProviderType;
	disabled: boolean;
}

interface FormValues {
	[ToggleName.IS_FACEBOOK_LOGIN_DISABLED]: boolean;
	[ToggleName.IS_GOOGLE_LOGIN_DISABLED]: boolean;
	[ToggleName.IS_APPLE_ID_LOGIN_DISABLED]: boolean;
	[ToggleName.IS_LOGIN_2FA_ENABLED]: boolean;
}

interface FormAction {
	name: ToggleName;
	value: boolean;
}

const initialState = {
	[ToggleName.IS_FACEBOOK_LOGIN_DISABLED]: false,
	[ToggleName.IS_GOOGLE_LOGIN_DISABLED]: false,
	[ToggleName.IS_APPLE_ID_LOGIN_DISABLED]: false,
	[ToggleName.IS_LOGIN_2FA_ENABLED]: false
};

const formStateReducer = (state: FormValues, { name, value }: FormAction) => {
	switch (name) {
		case ToggleName.IS_FACEBOOK_LOGIN_DISABLED:
			return { ...state, [ToggleName.IS_FACEBOOK_LOGIN_DISABLED]: value };
		case ToggleName.IS_GOOGLE_LOGIN_DISABLED:
			return { ...state, [ToggleName.IS_GOOGLE_LOGIN_DISABLED]: value };
		case ToggleName.IS_APPLE_ID_LOGIN_DISABLED:
			return { ...state, [ToggleName.IS_APPLE_ID_LOGIN_DISABLED]: value };
		case ToggleName.IS_LOGIN_2FA_ENABLED:
			return { ...state, [ToggleName.IS_LOGIN_2FA_ENABLED]: value };
		default:
			return state;
	}
};

const mapProviderTypeToName = (providerType: ProviderType): ToggleName => {
	if (providerType === ProviderType.FACEBOOK) return ToggleName.IS_FACEBOOK_LOGIN_DISABLED;
	if (providerType === ProviderType.APPLE_ID) return ToggleName.IS_APPLE_ID_LOGIN_DISABLED;
	return ToggleName.IS_GOOGLE_LOGIN_DISABLED;
};

const SocialLoginPermissions = () => {
	const [formState, updateFormState] = useReducer(formStateReducer, initialState);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
	const [error, setError] = useState(null);
	const formError = useAuthErrorMessage(error);
	const { clientName } = useClientConfig();

	useScrollToTop([formError]);

	const { check: manualSessionCheck } = useManualSessionCheck();

	const handleChange = async ({ name, value }: FormAction) => {
		if (!(await manualSessionCheck())) return;
		updateFormState({ name, value });
		setIsRequestPending(true);

		let url = null;
		let method: Method | null = null;
		let data = null;

		if (name === ToggleName.IS_FACEBOOK_LOGIN_DISABLED) {
			url = endpoint.socialLoginPermissions;
			method = 'PUT';
			data = { providerType: ProviderType.FACEBOOK, disabled: value };
		}
		if (name === ToggleName.IS_GOOGLE_LOGIN_DISABLED) {
			url = endpoint.socialLoginPermissions;
			method = 'PUT';
			data = { providerType: ProviderType.GOOGLE, disabled: value };
		}
		if (name === ToggleName.IS_APPLE_ID_LOGIN_DISABLED) {
			url = endpoint.socialLoginPermissions;
			method = 'PUT';
			data = { providerType: ProviderType.APPLE_ID, disabled: value };
		}

		if (url === null || method === null || data === null) return;
		try {
			await axiosInstance({ method, url, data });
			setError(null);
		} catch (error: any) {
			updateFormState({ name, value: !value });
			if (!getIsHiddenError(error)) {
				setError(error.response);
			}
		}
		setIsRequestPending(false);
	};

	useEffect(() => {
		(async () => {
			if (!(await manualSessionCheck())) return;
			setIsLoadingInitialData(true);
			try {
				/* eslint-disable */
				const { data: socialProviders } = await axiosInstance.get(endpoint.socialLoginPermissions);
				/* eslint-enable */

				setError(null);
				socialProviders.forEach(({ providerType, disabled }: SocialProvider) => {
					updateFormState({ name: mapProviderTypeToName(providerType), value: disabled });
				});
			} catch (e) {
				if (!getIsHiddenError(error)) {
					setError(e as any);
				}
			}
			setIsLoadingInitialData(false);
		})();
	}, []);

	if (isLoadingInitialData) return <Spinner />;

	return (
		<>
			<h2>
				<FormattedMessage {...messages.title} />
			</h2>
			<div className={styles.formContainer}>
				{formError && (
					<FormMessage className={styles.errorMessage} type={FormMessageType.ERROR}>
						{formError}
					</FormMessage>
				)}

				<div className={styles.text}>
					<FormattedMessage
						{...messages.body}
						values={{
							client: <>{clientName}</>
						}}
					/>
				</div>
				<form onSubmit={(e) => e.preventDefault()}>
					<Toggle
						disabled={isRequestPending}
						label={messages.enableFacebookLogin}
						name={ToggleName.IS_FACEBOOK_LOGIN_DISABLED}
						value={formState[ToggleName.IS_FACEBOOK_LOGIN_DISABLED]}
						onChange={() =>
							handleChange({
								name: ToggleName.IS_FACEBOOK_LOGIN_DISABLED,
								value: !formState[ToggleName.IS_FACEBOOK_LOGIN_DISABLED]
							})
						}
					/>
					<Toggle
						disabled={isRequestPending}
						label={messages.enableGoogleLogin}
						name={ToggleName.IS_GOOGLE_LOGIN_DISABLED}
						value={formState[ToggleName.IS_GOOGLE_LOGIN_DISABLED]}
						onChange={() =>
							handleChange({
								name: ToggleName.IS_GOOGLE_LOGIN_DISABLED,
								value: !formState[ToggleName.IS_GOOGLE_LOGIN_DISABLED]
							})
						}
					/>
					<Toggle
						disabled={isRequestPending}
						label={messages.enableAppleIdLogin}
						name={ToggleName.IS_APPLE_ID_LOGIN_DISABLED}
						value={formState[ToggleName.IS_APPLE_ID_LOGIN_DISABLED]}
						onChange={() =>
							handleChange({
								name: ToggleName.IS_APPLE_ID_LOGIN_DISABLED,
								value: !formState[ToggleName.IS_APPLE_ID_LOGIN_DISABLED]
							})
						}
					/>
				</form>
			</div>
		</>
	);
};

export default SocialLoginPermissions;
