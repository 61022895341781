import React, { ChangeEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import FieldError from '../FieldError/FieldError';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import styles from './CountdownInput.module.scss';

const messages = defineMessages({
	retry: {
		id: 'base.retry',
		defaultMessage: 'Retry'
	}
});

interface CountdownInputProps {
	autoComplete?: string;
	className?: string;
	error?: string;
	handleRetryButtonClick: () => void;
	id?: string;
	isCountdownHidden: boolean;
	isExpired?: boolean;
	label?: string;
	name?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	pattern?: string;
	placeholder?: string;
	formattedTimeLeft: string;
	type: string;
	value: string;
}

const CountdownInput = ({
	autoComplete = 'off',
	className,
	error,
	handleRetryButtonClick,
	isCountdownHidden,
	isExpired,
	formattedTimeLeft,
	label,
	name,
	...restProps
}: CountdownInputProps) => {
	const { formatMessage } = useIntl();
	return (
		<div className={classNames(styles.container, className)}>
			<label className={styles.label} htmlFor={name}>
				{label}
			</label>
			<FieldError error={error} />
			<div className={styles.inputWrapper}>
				<input
					className={styles.input}
					name={name}
					autoComplete={autoComplete}
					{...restProps}
				/>
				<div className={styles.countdown}>
					{(() => {
						if (isCountdownHidden) return null;
						if (isExpired) {
							return (
								<Button
									buttonStyle={ButtonStyle.LINK}
									className={styles.retryButton}
									onClick={handleRetryButtonClick}
									type={ButtonType.BUTTON}
								>
									{formatMessage(messages.retry)}
								</Button>
							);
						}
						return <span className={styles.time}>{formattedTimeLeft}</span>;
					})()}
				</div>
			</div>
		</div>
	);
};

export default CountdownInput;
