import { FC, useCallback, ChangeEvent, useState } from 'react';
import TwoFAProviderView from './TwoFAProviderView';
import { useAppDispatch } from '../../redux/Store';
import { useSelector } from 'react-redux';
import { getTwoFaRequest } from '../../redux/TwoFaSlice/TwoFaSelectors';
import TwoFaActions from '../../redux/TwoFaSlice/TwoFaActions';
import { useTwoFaConsumer } from '../../hooks/useTwoFa';
import { TwoFaCode } from '../../redux/TwoFaSlice/TwoFaTypes';
import { AxiosError, AxiosResponse } from 'axios';
import { RemoteError, RemoteStatus } from '../../types/Remote';
import useTwoFaProcessor from '../../hooks/useTwoFaProcessor';
import TwoFAType from '../../enums/TwoFAType';

// Axios, our request library is configured in useAxios.js file to catch "2fa required" or related responses. It then changes App state so that this view is shown...

export const VIEW = {
	RETRY_COUNT_USED: 'RETRY_COUNT_USED',
	EXPIRED: 'EXPIRED',
	DEFAULT: 'DEFAULT',
	ERROR: 'ERROR',
};

const TwoFAProvider: FC = () => {
	const dispatch = useAppDispatch();
	const { errorCode, authType, txId, twoFaCode, requestData, expDate, callEnabled } =
		useSelector(getTwoFaRequest)!;

	const [originalTwoFaType] = useState(authType);

	const twoFaConsumer = useTwoFaConsumer(requestData.twoFaConsumerId);

	const onReset = useCallback(() => dispatch<any>(TwoFaActions.resetData()), [dispatch]);
	const onErrorCodeChange = useCallback(
		(value: TwoFaCode) => dispatch(TwoFaActions.setCode(value)),
		[dispatch]
	);

	const onSuccess = useCallback(
		(response: AxiosResponse) => {
			twoFaConsumer?.onSuccess?.call(this, response);
			onReset();
		},
		[twoFaConsumer?.onSuccess, onReset]
	);

	const onFailure = useCallback(
		(response: AxiosError<RemoteError>) => {
			twoFaConsumer?.onFailure?.call(this, response);
			onReset();
		},
		[twoFaConsumer?.onFailure, onReset]
	);

	const {
		ref,
		code,
		onCodeChange,
		onSubmit,
		onFallback,
		inputError,
		actionStatus,
		isResubmitRequired,
		timeRemaining,
		isExpired,
	} = useTwoFaProcessor({
		config: { errorCode, authType, txId, twoFaCode, requestData, expDate },
		onSuccess,
		onFailure,
		onErrorCodeChange,
	});

	return (
		<TwoFAProviderView
			code={code}
			txId={txId}
			callEnabled={callEnabled}
			formattedTimeLeft={timeRemaining}
			isCodeError={inputError}
			onCancel={() =>
				onReset()
			}
			onCodeChange={(event: ChangeEvent<HTMLInputElement>) => onCodeChange(event.target.value.trim())}
			onRetry={() => onSubmit(true)}
			onSubmit={() => onSubmit()}
			onRequestSMS={(newTx: boolean) => onFallback(newTx, 'sms')}
			onRequestCall={() => onFallback(originalTwoFaType === TwoFAType.PUSH_ME, 'call')}
			type={authType}
			isExpired={isExpired}
			view={
				actionStatus === RemoteStatus.Error ? VIEW.ERROR : isResubmitRequired ? VIEW.RETRY_COUNT_USED : isExpired ? VIEW.EXPIRED : VIEW.DEFAULT
			}
			error={inputError}
		/>
	);
};

export default TwoFAProvider;