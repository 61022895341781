import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import TwoFaSlice from './TwoFaSlice/TwoFaSlice';
import AppSlice from './AppSlice/AppSlice';
import LanguageSlice from './LanguageSlice/LanguageSlice';

export const appReducer = combineReducers({
	twoFa: TwoFaSlice.reducer,
	app: AppSlice.reducer,
	language: LanguageSlice.reducer,
});

const store = configureStore({
	reducer: appReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
