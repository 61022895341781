import React, { AllHTMLAttributes } from 'react';
import styles from './Modal.module.scss';
import classNames from 'classnames';

interface Props extends AllHTMLAttributes<HTMLElement> {
	children: React.ReactChildren | React.ReactNode | string;
}

function Modal({ children, className }: Props) {
	return (
		<div className={styles.container}>
			<div className={classNames(styles.content, className)}>{children}</div>
		</div>
	);
}

export default Modal;
